import React from 'react'
// import SectionTitleS2 from '../../components/SectionTitleS2';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './style.scss';


const TimeLine = (props) => {

    return (
        <div className="wpo-room-area section-padding timeline-section">
            <div className="container">
                {/* <div className="row align-items-center justify-content-center">
                    <div className="col-xl-6 col-md-8">
                        <SectionTitleS2 MainTitle={props.title} />
                    </div>
                </div> */}
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-12 col-md-12">
                        <VerticalTimeline>
                            {props.timelineElements && props.timelineElements.length > 0 ?
                                props.timelineElements.map((e, i) => {
                                    return <VerticalTimelineElement key={i}
                                        className="vertical-timeline-element--work"
                                        // contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    >
                                        <h3 className="vertical-timeline-element-title">{e.title}</h3>
                                    </VerticalTimelineElement>
                                })
                                : null}
                        </VerticalTimeline>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeLine;