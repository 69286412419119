import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import SearchRooms from '../../components/SearchRooms/SearchRooms';
import Scrollbar from '../../components/scrollbar';
import Logo from '../../images/logo2.png'
import Footer from '../../components/footer';
import SearchSection from '../../components/SearchSection';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomLoader from '../../components/CustomLoader';
import { Helmet } from "react-helmet";
import { Button } from "reactstrap";

const SearchResults = () => {

    const location = useLocation();
    const [value, setValue] = useState(location.state?.value || {});
    const [loading, setLoading] = useState(false)
    const [rooms, setRooms] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalListCount, setTotalListCount] = useState(0);
    const [seeMore, setSeeMore] = useState(true);
    const [sortBy, setSortBy] = useState(1);
    const itemsPerPage = 50;
    const push = useNavigate();

    const loadMoreItems = async (resetPage) => {
        const nextPage = resetPage ? 2 : currentPage + 1;
        setValue(location.state?.value)
        // if (location.state?.value && Object.keys(location.state?.value).length > 0) {
        setLoading(true);
        try {
            fetch(process.env.REACT_APP_API_BASE_URL + '/ad/search?page=' + (resetPage ? 1 : currentPage) + '&perPage=' + itemsPerPage, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(location.state?.value),
            })
                .then(response => {
                    if (!response.ok) {
                        setLoading(false);
                        throw new Error('Server Error');
                    }
                    setLoading(false);
                    return response.json();
                })
                .then(data => {
                    // setRooms(data?.data);
                    if (data?.data?.length > 0) {
                        if (resetPage) {
                            setRooms(data?.data);
                        } else {
                            setRooms((prevItems) => {
                                // Extract unique values from the incoming data
                                const uniqueData = data?.data.filter((item) => !prevItems.some((prevItem) => prevItem._id === item._id));

                                // Use a Set to efficiently eliminate duplicates
                                const uniqueSet = new Set([...prevItems, ...uniqueData]);

                                // Convert the Set back to an array
                                const uniqueArray = Array.from(uniqueSet);

                                return uniqueArray;
                            });
                        }
                        setTotalListCount(data?.totalCount)
                        if (data?.data?.length < itemsPerPage) {
                            setSeeMore(false);
                        }
                        setCurrentPage(nextPage);
                        setLoading(false);
                    } else {
                        setSeeMore(false);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetching Ads', error);
                    push("/")
                    setLoading(false);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadMoreItems(true);
    }, [location.state?.value]);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Search</title>
                <link rel="canonical" href="https://toletout.ie/search-result" />
                <meta name='description' content='Empowering Home Journeys. Where Every Door Tells a Unique Story.' />
                <meta property="og:title" content="Search" />
                <meta property="og:description" content="Empowering Home Journeys. Where Every Door Tells a Unique Story." />
                <meta name="twitter:title" content="Search" />
                <meta name="twitter:description" content="Empowering Home Journeys. Where Every Door Tells a Unique Story." />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <CustomLoader show={loading} />
            {/* <PageTitle pageTitle={'Hotel Booking Search'} pagesub={'Search'}/>  */}
            <SearchSection svClass='mt-0 pt-50 pb-50 section-bg' value={value} />
            {rooms?.length > 0 ? <section className="wpo-shop-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SearchRooms
                                rooms={rooms}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                            />
                        </div>
                    </div>
                </div>
            </section> : <h3 className='pd-20 height-400 text-align-center mt-100'>No Result Found</h3>}
            <div className="row pt-20 pb-20">
                <div className='text-align-center'>
                    {totalListCount && totalListCount > rooms?.length ?
                        <Button onClick={() => loadMoreItems(false)}
                            disabled={rooms?.length === 0} className="theme-btn">See More</Button>
                        : null}
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default SearchResults;