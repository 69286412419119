import React, { useContext } from 'react';
import { ImageProvider } from './ImageContext';
import TypeFormIndex from './TypeFormIndex';

const RenderTypeForm = ({ cities, countries }) => {
    return (
        <ImageProvider>
            <TypeFormIndex cities={cities} countries={countries} />
        </ImageProvider>
    );
};

export default RenderTypeForm;
