import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import Newslatter from '../../components/Newslatter/Newslatter';
import AboutUs from './AboutUs';
import TimeLine from '../Timeline';
import { Helmet } from "react-helmet";

const AboutPage = () => {
    const requestTimelineElements = [
        { title: "Find a suitable Home" },
        { title: "Place a Request" },
        { title: "Get Notified when Approved" }
    ]

    const placeTimelineElements = [
        { title: "Add details of your Home" },
        { title: "Add appropriate Images/Videos" },
        { title: "Submit the Request" },
        { title: "Get Notified by our Team when Approved" }
    ]
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Our Story</title>
                <link rel="canonical" href="https://toletout.ie/about" />
                <meta name='description' content='Uncover our story, mission, and the passionate team driving us forward. Get to know us better as we share the values that define our commitment to excellence.' />
                <meta property="og:title" content="Our Story" />
                <meta property="og:description" content="Uncover our story, mission, and the passionate team driving us forward. Get to know us better as we share the values that define our commitment to excellence." />
                <meta name="twitter:title" content="Our Story" />
                <meta name="twitter:description" content="Uncover our story, mission, and the passionate team driving us forward. Get to know us better as we share the values that define our commitment to excellence." />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'Our Story'} pagesub={'Our Story'} />
            <AboutUs />
            <br />
            <h1 className='text-center f-s-18 listing-process'>FINDING SUITABLE HOME PROCEDURE</h1>
            <div className='row'>
                <TimeLine title="" timelineElements={requestTimelineElements} />
            </div>
            <h1 className='text-center f-s-18 listing-process'>AD LISTING PROCEDURE</h1>
            <TimeLine title="" timelineElements={placeTimelineElements} />
            <Newslatter nClass={'section-bg'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default AboutPage;
