import React, { useState, useEffect } from 'react';
// import RenderBoringForm from './RenderBoringForm';
import RenderTypeForm from './RenderTypeForm';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddListing = () => {
    const push = useNavigate()
    const [value, setValue] = useState({
        address: '',
        country: '',
        city: '',
        eircode: '',
        phone: '',
        roomType: '',
        bathroomType: '',
        foodpreference: '',
        dailyRent: '',
        deposit: '',
        aboutProperty: '',
        aboutOwner: '',
        reference: '',
        totalRoom: '',
        totalBathroom: '',
        totalOccupants: '',
        startRentalDate: '',
        endRentalDate: '',
        rentalOption: '',
        genderPreference: '',
        maxRenter: '',
        remember: false
    });
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    // Fetch the country list
    const fetchCountries = async () => {
        try {
            const response = await fetch("https://countriesnow.space/api/v0.1/countries");
            if (response.ok) {
                const data = await response.json();
                setCountries(data.data);
                setValue({ country: 'Ireland' });
            } else {
                console.error("Failed to fetch countries data");
            }
        } catch (error) {
            console.error("Error fetching countries data: ", error);
        }
    };

    // fetch Cities
    const fetchCities = (country) => {
        const selectedCountry = countries.find((c) => c.country === country);
        setCities(selectedCountry?.cities || []);
    };

    useEffect(() => {
        fetchCountries();
        fetchCities(value.country);
    }, [value.country]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            push('/login');
            toast.error('Please login before posting the Ad');
        }
    }, []);

    return (
        // <RenderBoringForm cities={cities} countries={value.country} value={value} setValue={setValue} />
        <RenderTypeForm cities={cities} countries={value.country} />
    );
};

export default AddListing;
