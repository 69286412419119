import * as React from 'react';
import { Helmet } from "react-helmet";

export default function TermsAndCondition() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms & Condition</title>
                <link rel="canonical" href="https://toletout.ie/faq" />
                <meta name='description' content='Transparency and trust are our foundation, and here you will find the guidelines that govern your experience.' />
                <meta property="og:title" content="Terms & Condition" />
                <meta property="og:description" content="Transparency and trust are our foundation, and here you will find the guidelines that govern your experience." />
                <meta name="twitter:title" content="Terms & Condition" />
                <meta name="twitter:description" content="Transparency and trust are our foundation, and here you will find the guidelines that govern your experience" />
            </Helmet>
            <div className='pt-50 pb-50 pl-100 pr-100'>
                <h1>Registration Terms and Conditions</h1>

                <p><strong>1. Introduction</strong></p>
                <p>Welcome to ToLetOut. By registering as a user, you agree to comply with and be bound by the following terms and conditions. Please review these terms carefully.
                    If you do not agree to these terms, you should not proceed with registration.</p>

                <p><strong>2. Definitions</strong></p>
                <p>
                    <strong>"User"</strong> refers to any person or entity registering on this Website.<br />
                    <strong>"Host"</strong> refers to registered users listing properties for rent on the Website.<br />
                    <strong>"Guest"</strong> refers to registered users booking properties on the Website.
                </p>

                <p><strong>3. Acceptance of Terms</strong></p>
                <p>By registering on this Website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>

                <p><strong>4. User Registration</strong></p>
                <ul>
                    <li>You must provide accurate and complete information during the registration process.</li>
                    <li>You agree to maintain and promptly update your registration information to keep it accurate and current.</li>
                </ul>

                <p><strong>5. User Responsibilities</strong></p>
                <p><strong>a. Users must not:</strong></p>
                <ul>
                    <li>Create more than one account, misrepresent their identity, or impersonate others.</li>
                    <li>Post, transmit, or display any unlawful, offensive, or harmful content.</li>
                    <li>Misuse or infringe upon the intellectual property of the Website.</li>
                    <li>Violate the privacy of others or misuse personal information.</li>
                </ul>
                <p><strong>b. Users are responsible for adhering to local laws and regulations.</strong></p>

                <p><strong>6. Privacy Policy</strong></p>
                <p>ToLetOut has a privacy policy in place to govern the collection, use, and storage of personal data. By registering on this Website, you acknowledge and agree to the terms of the privacy policy.</p>

                <p><strong>7. Security</strong></p>
                <ul>
                    <li>You are responsible for maintaining the confidentiality and security of your account and password.</li>
                    <li>You agree to immediately notify ToLetOut of any unauthorized use of your account.</li>
                </ul>

                <p><strong>8. Termination</strong></p>
                <p>ToLetOut reserves the right to terminate or suspend your account at its discretion. You may request account removal by contacting our support.</p>

                <p><strong>9. Governing Law</strong></p>
                <p>These terms and conditions are governed by the laws of Ireland.</p>

                <p><strong>10. Changes to Terms and Conditions</strong></p>
                <p>ToLetOut reserves the right to modify these terms and conditions. Users will be notified of changes.</p>

                <p><strong>11. Contact Information</strong></p>
                <p>For inquiries and complaints, please contact contact@toletout.ie.</p>

                <p><strong>12. Severability</strong></p>
                <p>If any part of these terms and conditions is found unenforceable, the rest of the terms remain valid.</p>

                <p><strong>13. Entire Agreement</strong></p>
                <p>These terms and conditions represent the entire agreement between users and ToLetOut.</p>
            </div>
        </>
    );
}