import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import CustomLoader from "../CustomLoader";
import { eirCodeDecode } from "../../utils/eircodeDecode";
import {
    Select,
    MenuItem,
} from '@mui/material';


const Rooms2 = () => {
    const [listings, setListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalListCount, setTotalListCount] = useState(0);
    const [seeMore, setSeeMore] = useState(true);
    const [loading, setLoading] = useState(false)
    const [sortBy, setSortBy] = useState(1);
    const itemsPerPage = 50;

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const titleStyle = {
        textTransform: 'capitalize',
    };

    const loadMoreItems = async (resetPage) => {
        const nextPage = resetPage ? 2 : currentPage + 1;
        setLoading(true);
        try {
            fetch(process.env.REACT_APP_API_BASE_URL + '/ad/?page=' + (resetPage ? 1 : currentPage) + '&perPage=' + itemsPerPage)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Server Error');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data?.data?.length > 0) {
                        if (resetPage) {
                            setListings(data?.data);
                        } else {
                            setListings((prevItems) => {
                                // Extract unique values from the incoming data
                                const uniqueData = data?.data.filter((item) => !prevItems.some((prevItem) => prevItem._id === item._id));

                                // Use a Set to efficiently eliminate duplicates
                                const uniqueSet = new Set([...prevItems, ...uniqueData]);

                                // Convert the Set back to an array
                                const uniqueArray = Array.from(uniqueSet);

                                return uniqueArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                            });
                        }
                        setTotalListCount(data?.totalCount)
                        if (data?.data?.length < itemsPerPage) {
                            setSeeMore(false);
                        }
                        setCurrentPage(nextPage);
                        setLoading(false);
                        setSortBy(1)
                    } else {
                        setSeeMore(false);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetching Ads', error);
                    setLoading(false);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const changeHandler = (e) => {
        setSortBy(e.target.value || 0)
        sortListing(e.target.value);
    }

    const sortListing = (option) => {
        switch (option) {
            case '1':
                setListings([...listings.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))]);
                break;
            case '2':
                setListings([...listings.sort((a, b) => a.dailyRent - b.dailyRent)]);
                break;
            case '3':
                setListings([...listings.sort((a, b) => b.dailyRent - a.dailyRent)]);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadMoreItems(true);
    }, []);

    // useEffect(() => {
    //   const loadMore = async () => {
    //     try {
    //       const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/ad/?page=' + currentPage + '&perPage=' + itemsPerPage);
    //       if (response.ok) {
    //         const data = await response.json();
    //         if (data.length > 0) {
    //           setListings((prevItems) => [...prevItems, ...data?.data]);
    //           setTotalListCount(data?.totalCount)
    //         }
    //       }
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    //   };

    //   loadMore();
    // }, [currentPage]);

    return (
        <div className="wpo-room-area section-bg section-padding">
            <CustomLoader show={loading} />
            <div className="container">

                <div className='display-flex justify-flex-end align-items-center'>
                    <p className="pr-10 m-0">Sort By</p>
                    <Select
                        labelId="sortBy"
                        id="sortBy"
                        value={sortBy}
                        variant="outlined"
                        name="sortBy"
                        className=''
                        label="Sort By"
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        onChange={(e) => changeHandler(e)}>
                        {/* <MenuItem value='0'>Recommended</MenuItem> */}
                        <MenuItem value='1'>Latest</MenuItem>
                        <MenuItem value='2'>Price (Low to High)</MenuItem>
                        <MenuItem value='3'>Price (High to Low)</MenuItem>
                    </Select>
                </div>
                <br />
                <div className="room-wrap">
                    <div className="row">
                        {listings && listings.length > 0 &&
                            // listings.slice(0, 6).map((list, index) => (
                            listings.map((list, index) => (
                                <div className="col-lg-4 col-md-6 col-12" key={index}>
                                    <Link onClick={ClickHandler} to={`/details/${list._id}`}>
                                        <div className="room-item">
                                            <div className="room-img">
                                                <img className="height-200" src={list.images[0]} alt="" />
                                            </div>
                                            <div className="room-content">
                                                {/* <h2 style={titleStyle}><Link onClick={ClickHandler} to={`/details/${list._id}`}>{list?.roomType} Room with {list?.bathroomType} Bathroom</Link></h2> */}
                                                <h2 style={titleStyle}>{list.roomType} Room with {list?.bathroomType} Bathroom
                                                    [{list?.rentalOption === 'permanent' ? 'Permanent' : 'Temporary'} Accomodation]</h2>
                                                <h4 style={titleStyle}>{eirCodeDecode(list?.eircode) !== null ? eirCodeDecode(list?.eircode) : list?.city}</h4>
                                                {/* ad.city */}
                                                <ul>
                                                    {/* <li><i className="fi flaticon-expand-arrows"></i>{product.sqm} sqm</li> */}
                                                    <li><i className="fi flaticon-bed"></i>{list.totalRoom} Bed</li>
                                                    <li><i className="fi flaticon-bathtub"></i>{list.totalBathroom} Bathroom</li>
                                                </ul>
                                                <h3>€{list.dailyRent} <span>/ Day</span></h3>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                    </div>
                    <div className="row pt-20 pb-20">
                        <div className='text-align-center'>
                            {totalListCount && totalListCount > listings?.length ?
                                <Button onClick={() => loadMoreItems(false)}
                                    disabled={listings?.length === 0} className="theme-btn">See More</Button>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null)(Rooms2);