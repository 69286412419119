import React, { Fragment, useState, Suspense, lazy } from 'react';
import { Helmet } from "react-helmet";
import Footer from '../../components/footer';
import Hero from '../../components/hero';
import Navbar from '../../components/Navbar'
import Newslatter from '../../components/Newslatter/Newslatter';

// import Rooms from '../../components/Rooms/Rooms';
import Scrollbar from '../../components/scrollbar'
import SearchSection from '../../components/SearchSection';
import Logo from '../../images/logo2.png'

// import GuestWorkSection from '../GuestWorkSection';
// import Study from '../Study';
import CustomLoader from '../../components/CustomLoader';
// import WhyUs from '../WhyUs';
const Rooms = lazy(() => import('../../components/Rooms/Rooms'));
const WhyUs = lazy(() => import('../WhyUs'));
const Study = lazy(() => import('../Study'));
const GuestWorkSection = lazy(() => import('../GuestWorkSection'));

const HomePage = () => {
    const [loading, setLoading] = useState(true);
    React.useEffect(() => {
        window.scroll(0, 0);
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, []);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://toletout.ie/" />
                <meta name='description' content='Empowering Home Journeys. Where Every Door Tells a Unique Story. Rent a private or shared accommodation in Ireland, namely Dublin, Cork, Galway, Waterford, Wexford, Sligo, Donegal or Limerick. Convenient accommodation for students & professionals in Ireland' />
                <meta property="og:title" content="Home" />
                <meta property="og:description" content="Empowering Home Journeys. Where Every Door Tells a Unique Story. Rent a private or shared accommodation in Ireland, namely Dublin, Cork, Galway, Waterford, Wexford, Sligo, Donegal or Limerick. Convenient accommodation for students & professionals in Ireland" />
                <meta name="twitter:title" content="Home" />
                <meta name="twitter:description" content="Empowering Home Journeys. Where Every Door Tells a Unique Story. Rent a private or shared accommodation in Ireland, namely Dublin, Cork, Galway, Waterford, Wexford, Sligo, Donegal or Limerick. Convenient accommodation for students & professionals in Ireland" />
            </Helmet>
            <CustomLoader show={loading} />
            <Navbar topbarBlock={'wpo-header-style-3'} hclass={'wpo-header-style-3'} Logo={Logo} />
            <Hero />
            <SearchSection />
            <Suspense fallback={<div></div>}>
                <Rooms />
                <WhyUs />
                <Study />
                <GuestWorkSection />
            </Suspense>
            <Newslatter />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;