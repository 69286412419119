import React, { useState, useEffect } from 'react'
import amenities from '../../images/hotel.png';
import RoomSidebar from './RoomSidebar';
import MapContainer from '../../components/GoogleMaps';
import { eirCodeDecode } from '../../utils/eircodeDecode'

const RoomDetails = (props) => {

    const [adDetails, setAdDetails] = useState(props?.adDetails || {})
    const [includedItems, setIncludedItems] = useState(props?.adDetails && props?.adDetails?.amenitiesList ? props?.adDetails?.amenitiesList[0] : {} || {})
    // const [includedItems, setIncludedItems] = useState(props?.adDetails && props?.adDetails?.amenitiesList ? Object.keys(props?.adDetails?.amenitiesList[0]).filter((item) => props?.adDetails?.amenitiesList[0][item] === true) : {} || {})

    const titleStyle = {
        textTransform: 'capitalize',
    };

    useEffect(() => {
        setAdDetails(props.adDetails)
        setIncludedItems(props.adDetails && props.adDetails.hasOwnProperty('amenitiesList') && props.adDetails?.amenitiesList ? props?.adDetails?.amenitiesList[0] : {})
        // setIncludedItems(props.adDetails && props.adDetails.hasOwnProperty('amenitiesList') && props.adDetails?.amenitiesList ? Object.keys(props?.adDetails?.amenitiesList[0]).filter((item) => props?.adDetails?.amenitiesList[0][item] === true) : {})
    }, [props.adDetails]);

    return (
        <div className="Room-details-area pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <div className="room-description">
                            <h2 style={titleStyle}>
                                {adDetails?.roomType} Room with {adDetails?.bathroomType} Bathroom
                                [{adDetails?.rentalOption === 'permanent' ? 'Permanent' : 'Temporary'} Accomodation]
                                - {eirCodeDecode(adDetails?.eircode) !== null ? eirCodeDecode(adDetails?.eircode) : adDetails?.city}
                            </h2>
                            <br />
                            <h3>Description</h3>
                            <p className="p-wrap">{adDetails?.aboutProperty}</p>
                            <h3>About Host</h3>
                            <p>{adDetails?.aboutOwner}</p>
                            <h3>Gender Preference</h3>
                            <p>{adDetails?.genderPreference === 'male' ? 'Male' : adDetails?.genderPreference === 'female' ? 'Female' : 'Any'}</p>
                        </div>
                        <div className="room-details-service">
                            <div className="row">
                                <div className="room-details-item">
                                    <div className="row">
                                        <div className="col-md-5 col-sm-5">
                                            <div className="room-d-text">
                                                <div className="room-title">
                                                    <h2>Utilities Included</h2>
                                                </div>
                                                <ul>
                                                    {includedItems && Object.keys(includedItems)?.length && Object.keys(includedItems).map((amenity, index) => (
                                                        <li key={index} style={titleStyle}>
                                                            <span className={includedItems[amenity] ? 'included' : 'not-included'}>{amenity}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-7">
                                            <div className="room-d-img">
                                                <img src={amenities} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='map-container'> */}
                                    <MapContainer customEircode={adDetails?.eircode} />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <RoomSidebar adDetails={adDetails} />
                </div>
            </div>
        </div>
    )
}

export default RoomDetails;