import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './style.scss';
import { useDispatch } from 'react-redux';
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';
import CustomLoader from '../../components/CustomLoader';
import { verifyPassword } from '../../store/actions/authActions';

const VerifyAccount = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isCompleted, setCompleted] = useState(false);

    useEffect(() => {
        setCompleted(true);
        const search = window.location.search;
        let param = new URLSearchParams(search);
        const token = param.get('token');
        if (token) {
            dispatch(verifyPassword(token));
        } else {
            navigate('/');
        }
        setCompleted(false);
    }, [])

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <div className="flex-display flex-direction-row justify-space-between align-items-flex-start pt-16 pb-16 pr-8 pl-8 gap-16 h-100 forget-password-container">
                <CustomLoader show={isCompleted} />
                <div className="flex-display flex-direction-column text-align-center align-items-flex-start pt-60 pb-16 pr-8 pl-8 gap-16 w-100 h-100 custom-form-elements">
                    <span className="hind-h5-bold neutral-100 flex-display align-center justify-center w-100">Account Successfully Verified</span>
                    <br />
                    <span className="hind-normal-1-regular flex-display align-center text-align-justify justify-center neutral-100 w-100 resetForm">Please
                        <Link to="/login" className="hind-normal-1-regular secondary-1-400 pl-3"> Login</Link>.
                    </span>
                </div>
            </div>
        </Fragment>
    );
}

export default VerifyAccount;