import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import MobileMenu from "../MobileMenu";
import { Link, useNavigate } from 'react-router-dom'
import ProfileDropdown from './ProfileDropdown';
import { useDispatch } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

const Header = (props) => {
  const push = useNavigate()
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  }


  // Function to decode a JWT token
  const decodeToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem('token');
    if (token) {
      // Check token expiration
      const decodedToken = decodeToken(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        // Token has expired, log the user out
        setLoggedIn(false);
        dispatch(signOut(push));
      } else {
        // Token is valid, keep the user logged in
        setLoggedIn(true);

        const checkExpirationInterval = setInterval(() => {
          const token = localStorage.getItem('token');
          if (!token) {
            // Token is no longer present, stop checking
            clearInterval(checkExpirationInterval);
            setLoggedIn(false);
            // dispatch(signOut(push));
            return;
          }

          const decodedToken = decodeToken(token);
          if (decodedToken.exp * 1000 < Date.now()) {
            // Token has expired, log the user out
            setLoggedIn(false);
            dispatch(signOut(push));
            clearInterval(checkExpirationInterval);
          }
        }, 60000); // Check every minute (adjust as needed)
      }
    }
  }, []);

  return (
    <header id="header" className={props.topbarBlock}>
      {/* <HeaderTopbar /> */}
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="navbar-header">
                  <Link onClick={ClickHandler} className="navbar-brand logo" to="/home">
                    <img src={props.Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-10 col-md-3 col-3">
                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                  <button className="menu-close"><i className="ti-close"></i></button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link to="/" >Home</Link>
                    </li>
                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                    <li><Link onClick={ClickHandler} to="/faq">FAQ's</Link></li>
                    <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                    {!loggedIn ?
                      <li><Link onClick={ClickHandler} to="/login">Sign In</Link></li>
                      : null}
                    <li><Link onClick={ClickHandler} to="/addlisting">Become a Host</Link></li>
                    {/* {loggedIn ?
                      <>
                        <li className='cursor-pointer'><i className="fa fa-user-circle fa-2x color-white" aria-hidden="true"></i></li>
                        <li className='profileText'><Link onClick={ClickHandler} to="/contact">Profile</Link></li>
                      </>
                      : null} */}
                    {loggedIn ? (
                      <>
                        <li>
                          <ProfileDropdown setLoggedIn={setLoggedIn} /> {/* Use the ProfileDropdown component here */}
                        </li>
                        <li className='profileText'>
                          <Link onClick={ClickHandler} to="/profile">Profile</Link>
                        </li>
                      </>
                    ) : null}
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header;

