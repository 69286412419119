import { toast } from "react-toastify";

export const addListingPage = (userData, history, setLoading) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/ad/addListing', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && (response.status === 200 || response.status === 201)) {
            dispatch({ type: 'ADD_LISTING', payload: responseData });
            history('/');
            setLoading(false);
            toast.success('Listing added successful! Once approved you will be notified');
        } else if (response.status === 403) {
            // Login failed
            toast.error("Please Sign-In to Add the Listing");
            setLoading(false);
        } else {
            // Login failed
            toast.error(response.msg);
            setLoading(false);
        }
    } catch (error) {
        // Handle network errors
        toast.error('Network error. Please try again.');
        setLoading(false);
    }
};

export const removeListing = (id) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/ad/' + id, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ isActive: false }),
        });

        if (response.ok && (response.status === 200 || response.status === 201)) {
            dispatch({
                type: 'REMOVE_LISTING',
            });
            toast.success('Listing deleted successful');
        } else if (response.status === 403) {
            // Login failed
            toast.error("Please Sign-In to remove the Listing");
        } else {
            // Login failed
            toast.error(response.msg);
        }
    } catch (error) {
        // Handle network errors
        toast.error('Network error. Please try again.');
    }
};


export const updateListingPage = (id, userData, history, setLoading) => async (dispatch) => {
    try {
        userData['listId'] = id;
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/ad/addListing', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && (response.status === 200 || response.status === 201)) {
            dispatch({ type: 'UPDATE_LISTING', payload: responseData });
            history('/');
            setLoading(false);
            toast.success('Listing update successful! Once approved you will be notified');
        } else if (response.status === 403) {
            // Login failed
            toast.error("Please Sign-In to Add the Listing");
            setLoading(false);
        } else {
            // Login failed
            toast.error(response.msg);
            setLoading(false);
        }
    } catch (error) {
        // Handle network errors
        toast.error('Network error. Please try again.');
        setLoading(false);
    }
};

export const updateListingRequest = (id, isVerified) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/ad/update/' + id, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                isVerified: isVerified
            }),
        });

        const responseData = await response.json();

        if (response.ok && (response.status === 200 || response.status === 201)) {
            dispatch({ type: 'UPDATE_LISTING', payload: responseData });
            toast.success('Ad verification Status :', isVerified);
        } else if (response.status === 403) {
            // Login failed
            toast.error("Please Sign-In Update the Listing");
        } else {
            // Login failed
            toast.error(response.msg);
        }
    } catch (error) {
        // Handle network errors
        toast.error('Network error. Please try again.');
    }
};
