import React, { useState, Fragment, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';
// import './style.scss';
// Tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../../components/Table';
import { removeListing } from '../../store/actions/addListingActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { updateListingRequest } from '../../store/actions/addListingActions';
import RoomSinglePage from '../RoomSinglePage';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {/* <Typography>{children}</Typography> */}
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AdminListingPage = (props) => {
    const [value, setValue] = useState(0);
    const [rows, setRows] = useState([]);
    const [listCount, setListCount] = useState([]);
    const [viewingRows, setViewingRows] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const dispatch = useDispatch();
    const push = useNavigate();
    const [viewingId, setViewingId] = useState(0);

    const columns = [
        { id: 'aboutProperty', label: 'About Property' },
        { id: 'roomType', label: 'Room Type' },
        { id: 'dailyRent', label: 'Daily Rent' },
        { id: 'deposit', label: 'Deposit' },
        { id: 'rentalOption', label: 'Rental Option' },
        { id: 'startRentalDate', label: 'Start Rental Date' },
        { id: 'endRentalDate', label: 'End Rental Date' },
        { id: 'isVerified', label: 'Is Verified' },
        { id: 'verificationStatus', label: 'Verification Status' },
        // { id: 'requestReceived', label: 'Request Received' },
        { id: 'action', label: 'Action' }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Split character 
    // function splitTextAtCapitalLetters(text) {
    //     const parts = text.split(/(?=[A-Z])/);
    //     const result = parts.join(' ');
    //     return result;
    // }

    const editListing = (id) => {
        push('/addlisting/' + id);
    }

    const updateRequest = (id, isVerified) => {
        const updatedData = rows.map(item => {
            if (item._id === id) {
                return { ...item, isVerified: true, verificationStatus: isVerified === 'Approved' };
            } else {
                return item;
            }
        });
        setRows(updatedData);
        dispatch(updateListingRequest(id, isVerified));
    }

    const showListing = (id) => {
        setValue(1)
        setViewingId(id)
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token !== 'undefined' && token !== undefined) {
            fetch(process.env.REACT_APP_API_BASE_URL + '/ad/adminList', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Server Error');
                    }
                    return response.json();
                })
                .then(data => {
                    setRows(data?.data)
                    setListCount(data?.results)
                })
                .catch(error => {
                    console.error('There was a problem with the fetching Ads', error);
                });
        }
    }, []);

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <Grid className="userlistingWrapper">
                <Grid className="userlistingSection">
                    <p className='note'>Note: Click on request receive count to approve/reject the viewing</p>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="All Listing" {...a11yProps(0)} />
                                <Tab label="View Listing" {...a11yProps(1)} disabled={true} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <CustomTable
                                rows={rows}
                                columns={columns}
                                listCount={listCount}
                                updateRequest={updateRequest}
                                openPopup={openPopup}
                                showListing={showListing} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1} disabled>
                            {localStorage.getItem('isAdmin') ?
                                <RoomSinglePage id={viewingId} /> :
                                null}
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default AdminListingPage;