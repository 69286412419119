import React, { createContext, useState } from "react";

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState([]);

    const handleImageChange = async (e) => {
        const selectedImages = Array.from(e.target.files);
        const allowedFileTypes = ["jpg", "png", "jpeg", "gif"];
        const maxFileSize = 5 * 1024 * 1024;
        const validatedImages = selectedImages.filter((file) => {
            const fileExtension = file.name.split(".").pop().toLowerCase();
            const isValidType = allowedFileTypes.includes(fileExtension);
            const isSizeValid = file.size <= process.env.REACT_APP_FILE_SIZE_LIMIT || maxFileSize;

            if (!isValidType) {
                alert("File type not supported.");
            } else if (!isSizeValid) {
                alert("Please upload images less than 5MB.");
            }

            return isValidType && isSizeValid;
        });

        const imagePreviews = await Promise.all(
            validatedImages.map((file) => convertToBase64(file))
        );

        setImages((prevImages) => [...prevImages, ...imagePreviews]);
    };

    // Converting file to base64
    const convertToBase64 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
    }

    const handleRemoveImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    return (
        <ImageContext.Provider value={{ images, handleImageChange, handleRemoveImage }}>
            {children}
        </ImageContext.Provider>
    );
};
