import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';


const RoomSidebar = (props) => {

    const [adDetails, setAdDetails] = useState(props?.adDetails || {})
    const [startDate, setStartDate] = useState(props?.adDetails && props?.adDetails?.startRentalDate ? new Date(props.adDetails.startRentalDate) : new Date());
    const [endDate, setendDate] = useState(props?.adDetails && props?.adDetails?.endRentalDate ? new Date(props.adDetails.endRentalDate) : new Date());

    const [adult, setCount] = useState(0);
    const push = useNavigate();
    const adultincrementCount = () => {
        setCount(adult + 1);
    };
    const adultdecrementCount = () => {
        setCount(adult - 1);
    };

    const SubmitHandler = (e) => {
        e.preventDefault()
        if (localStorage.getItem('token')) {
            if (adult > 0) {
                if (adDetails?.userId !== localStorage.getItem("userId")) {
                    window.scrollTo(0, 0);
                    push('/viewingRequest/' + adDetails?._id, {
                        state: {
                            'startDate': startDate,
                            'endDate': endDate,
                            'totalGuest': adult
                        }
                    })
                }
            } else {
                alert('Guest count must be greater than 0.');
            }
        } else {
            toast.error("Please Sign-In to Request");
        }
    }

    const checkIfPresent = (key) => {
        return adDetails && adDetails?.withInDistance ? adDetails?.withInDistance[0][key] === true : false
    }


    useEffect(() => {
        setAdDetails(props?.adDetails)
        setStartDate(props?.adDetails && props?.adDetails?.startRentalDate ? new Date(props.adDetails.startRentalDate) : new Date())
        setendDate(props?.adDetails && props?.adDetails?.endRentalDate ? new Date(props.adDetails.endRentalDate) : new Date())
    }, [props?.adDetails]);

    return (
        <div className="col-lg-4 col-12">
            <div className="blog-sidebar room-sidebar">
                {/* Book Now */}
                <div className="widget check-widget">
                    <h3>Booking Information</h3>
                    <form onSubmit={SubmitHandler}>
                        <div className="input-group date">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                minDate={new Date(startDate)}
                                maxDate={new Date(endDate)}
                            />
                            <i className="fi flaticon-calendar"></i>
                        </div>

                        <div className="input-group date">
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setendDate(date)}
                                minDate={new Date(startDate)}
                                maxDate={new Date(endDate)}
                            />
                            <i className="fi flaticon-calendar"></i>
                        </div>
                        <div className="tourist-item">
                            <span>Total Guest</span>
                            <div className="tourist-item-group">
                                <button type="button" disabled={adult < 1} onClick={adultdecrementCount} id="adults_dec">-</button>
                                <input disabled id="adults_val" value={adult} type="number" required />
                                <button type="button" disabled={adult >= (adDetails.hasOwnProperty('maxRenter') ? adDetails?.maxRenter : 1)} onClick={adultincrementCount} id="adults_inc">+</button>
                            </div>
                        </div>
                        <div className="input-group date">
                            <Button className="theme-btn" type="submit" disabled={adDetails?.userId === localStorage.getItem('userId')}>Request Viewing</Button>
                        </div>
                    </form>
                </div>

                {/* Transportation */}
                {(checkIfPresent('busStop') || checkIfPresent('luas') || checkIfPresent('train')) ?
                    <div className="widget check-widget">
                        <h3>Transportation Information (By Walk)</h3>
                        {checkIfPresent('busStop') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Bus Stop</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('luas') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Luas</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('train') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Train</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                    </div>
                    : null}

                {/* Groceries */}
                {(checkIfPresent('lidl') || checkIfPresent('tesco') || checkIfPresent('aldi')) || checkIfPresent('centra')
                    || checkIfPresent('circleK') || checkIfPresent('superValue') || checkIfPresent('dealz')
                    || checkIfPresent('spar') || checkIfPresent('indianStore') ?
                    <div className="widget check-widget">
                        <h3>Near By</h3>
                        {checkIfPresent('aldi') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">ALDI</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('lidl') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">LIDL</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('tesco') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">TESCO</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('centra') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Centra</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('circleK') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Circle K</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('superValue') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">SuperValue</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('dealz') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Dealz</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('spar') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Spar</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {checkIfPresent('indianStore') ?
                            <div className='row'>
                                <div className="col-lg-6 col-6">Indian Store</div>
                                <div className="col-lg-6 col-6">1-5 mins</div>
                            </div> : null
                        }
                        {/* <div className='row'>
                            <div className="col-lg-6 col-6">Grocery Store</div>
                            <div className="col-lg-6 col-6">5 mins</div>
                        </div> */}
                    </div>
                    : null
                }
                <div className="wpo-contact-widget widget">
                    <h2>How We Can <br /> Help You!</h2>
                    <Link to="/contact" className="theme-btn">Contact Us</Link>
                </div>
            </div>
        </div>

    )
}

export default RoomSidebar;