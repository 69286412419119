import { toast } from "react-toastify";

export const sendRequestPage = (userData, history) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/viewing/addViewingRequest', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && (response.status === 200 || response.status === 201)) {
            dispatch({ type: 'SEND_REQUEST', payload: responseData });
            history('/');
            toast.success('Request Send successfully :). You will be notified once request is approved.');
        } else if (response.status === 403) {
            // Login failed
            toast.error(responseData.msg ? responseData.msg : "Please Sign-In to Add the Listing");
        } else {
            // Login failed
            toast.error(response.msg);
        }
    } catch (error) {
        // Handle network errors
        toast.error('Network error. Please try again.');
    }
};

export const updateViewingRequest = (id, status, history) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/viewing/' + id, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: status
            }),
        });

        const responseData = await response.json();

        if (response.ok && (response.status === 200 || response.status === 201)) {
            dispatch({ type: 'UPDATE_VIEWING', payload: responseData });
            history('/');
            toast.success('Viewing Request Updated! Status will be notified to the viewer');
        } else if (response.status === 403) {
            // Login failed
            toast.error("Please Sign-In to Add the Listing");
        } else {
            // Login failed
            toast.error(response.msg);
        }
    } catch (error) {
        // Handle network errors
        toast.error('Network error. Please try again.');
    }
};
