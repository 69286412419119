import { combineReducers } from 'redux'
import authReducer from "./authReducer";
import addListingReducer from './addListingReducer';
import listingRequestReducer from './listingRequestReducer';
import contactReducer from './contactReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    addListing: addListingReducer,
    listingRequest: listingRequestReducer,
    contactReducer: contactReducer
});

export default rootReducer;