export const eirCodeDecode = (eircode) => {
    let updatedEircode = null;
    if (eircode && eircode.split('')[0]) {
        const sanitizedEircode = eircode.replace(/\s/g, '').toUpperCase();
        // Extract the first three characters (the prefix)
        const eircodePrefix = sanitizedEircode.substring(0, 3);
        const DublinDistricts = {
            D01: 'Dublin 1',
            D02: 'Dublin 2',
            D03: 'Dublin 3',
            D04: 'Dublin 4',
            D05: 'Dublin 5',
            D06: 'Dublin 6',
            D07: 'Dublin 7',
            D08: 'Dublin 8',
            D09: 'Dublin 9',
            D10: 'Dublin 10',
            D11: 'Dublin 11',
            D12: 'Dublin 12',
            D13: 'Dublin 13',
            D14: 'Dublin 14',
            D15: 'Dublin 15',
            D16: 'Dublin 16',
            D17: 'Dublin 17',
            D18: 'Dublin 18',
            D19: 'Dublin 19',
            D20: 'Dublin 20',
            D21: 'Dublin 21',
            D22: 'Dublin 22',
            D23: 'Dublin 23',
            D24: 'Dublin 24',
        };
        updatedEircode = DublinDistricts[eircodePrefix] || null;
    }

    return updatedEircode;
};
