const initialState = {
    user: null, // Store user information when authenticated
    isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGN_UP':
        case 'SIGN_IN':
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
            };
        case 'SIGN_OUT':
            return {
                ...state,
                user: null,
                isAuthenticated: false,
            };
        case 'FORGET_PASSWORD':
            return {
                ...state,
                user: null,
                isAuthenticated: false
            };
        case 'SUBSCRIBE':
            return {
                ...state,
                user: null,
                isAuthenticated: false
            };
        default:
            return state;
    }
};

export default authReducer;
