import React, { useState, Fragment } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';
import { useDispatch } from 'react-redux';
import { signUp, googleSignUp } from '../../store/actions/authActions';
import GoogleButton from '../GoogleLogin';
import './style.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CustomLoader from '../../components/CustomLoader';
import { Helmet } from "react-helmet";
import axios from 'axios';

const SignUpPage = (props) => {

    const push = useNavigate();
    const dispatch = useDispatch();
    const [isCompleted, setCompleted] = useState(false);

    const [value, setValue] = useState({
        email: '',
        name: '',
        lastname: '',
        password: '',
        confirm_password: '',
        remember: false
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage',
        autoForceUpdate: this
    }));

    const submitForm = (e) => {
        setCompleted(true);
        e.preventDefault();
        if (validator.allValid() && value.remember !== false) {
            dispatch(signUp(value, push, setCompleted));
        } else {
            // Object.keys(value).forEach(field => {
            //     if (!validator.fieldValid(field)) {
            //         toast.error(validator.message(field, value[field], getValidationRules(field)));
            //     }
            // });
            validator.showMessages();
            setCompleted(false);
            if (value.password !== "" &&
                value.confirm_password !== "" &&
                value.password !== value.confirm_password) {
                toast.error("Confirm password doesn't the actual password");
            } else if (value.remember === false) {
                toast.error('Please accept our terms and conditions');
            } else {
                toast.error('Empty field is not allowed!');
            }
        }
    };

    // Uncomment if validation rule is required
    // const getValidationRules = (field) => {
    //     const rules = {
    //         name: 'required|alpha',
    //         lastname: 'required|alpha',
    //         email: 'required|email',
    //         password: 'required|min:6',
    //         confirm_password: `in:${value.password}`,
    //         remember: 'required'
    //     };
    //     return rules[field];
    // };

    const rememberHandler = () => {
        setValue({ ...value, remember: !value.remember });
    };

    const newLink = () => {
        window.open('/termsAndCondition', '_blank');
    };


    const handleLoginSuccess = (response) => {
        setCompleted(true);
        if (response && response.access_token) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    let user = res.data;
                    if (user?.verified_email) {
                        let credentials = {
                            email: user?.email,
                            name: user?.given_name,
                            lastname: user?.family_name
                        }
                        setValue({
                            ...value,
                            email: user?.email,
                            name: user?.given_name,
                            lastname: user?.family_name,
                            remember: !value.remember
                        });
                        dispatch(googleSignUp(credentials, push, setCompleted));
                    } else {
                        toast.error('Email isn`t verified');
                        setCompleted(false);
                    }
                })
                .catch((err) => {
                    setCompleted(false);
                    toast.error('Please try again after sometime');
                    console.log(err);
                });
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Login failed:', error);
        toast.error('Please try again after sometime');
    };

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Register</title>
                <link rel="canonical" href="https://toletout.ie/register" />
                <meta name='description' content=' Join our community, and lets create something amazing together. Signup/Register with Us.' />
                <meta property="og:title" content="Register" />
                <meta property="og:description" content=" Join our community, and lets create something amazing together. Signup/Register with Us." />
                <meta name="twitter:title" content="Register" />
                <meta name="twitter:description" content=" Join our community, and lets create something amazing together. Signup/Register with Us." />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <CustomLoader show={isCompleted} />
            <Grid className="loginWrapper">
                <Grid className="loginForm">
                    <h2>Signup</h2>
                    <p>Signup your account</p>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="First Name"
                                    value={value.name}
                                    variant="outlined"
                                    name="name"
                                    label="First Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('first name', value.name, 'required|alpha')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Last Name"
                                    value={value.lastname}
                                    variant="outlined"
                                    name="lastname"
                                    label="Last Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('last name', value.lastname, 'required|alpha')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="E-mail"
                                    value={value.email}
                                    variant="outlined"
                                    name="email"
                                    label="E-mail"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('email', value.email, 'required|email')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="password"
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Password"
                                    value={value.password}
                                    variant="outlined"
                                    name="password"
                                    label="Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('password', value.password, 'required|min:6')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="password"
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Confirm Password"
                                    value={value.confirm_password}
                                    variant="outlined"
                                    name="confirm_password"
                                    label="Confirm Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('confirm password', value.confirm_password, `in:${value.password}`)}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox
                                        name="remember"
                                        checked={value.remember}
                                        onChange={rememberHandler} />}
                                    label={
                                        <span onClick={newLink}>
                                            By clicking this, you agree to our <u className='color-blue'>Terms & Conditions</u>
                                        </span>
                                    }
                                />
                                {validator.message('Agree Terms', value.remember, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formFooter">
                                    <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Sign Up</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <hr className="solid" />
                    <Grid className="loginWithSocial">
                        <GoogleButton onSuccess={handleLoginSuccess} onFailure={handleLoginFailure} />
                        {/* <Button className="google"><i className="fa fa-google"></i></Button>
                                    <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                    <Button className="linkedin"><i className="fa fa-linkedin"></i></Button> */}
                    </Grid>
                    <p className="noteHelp">Already have an account? <Link to="/login">Return to Sign In</Link>
                    </p>
                    <div className="shape-img">
                        <i className="fi flaticon-honeycomb"></i>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default SignUpPage;