import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import Rooms2 from '../../components/Rooms2/Rooms2';
import Newslatter from '../../components/Newslatter/Newslatter';
import SearchSection from '../../components/SearchSection';

const RoomPage = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            {/* <PageTitle pageTitle={'Room'} pagesub={'Room'} /> */}
            <SearchSection svClass='mt-0 pt-50 section-bg' />
            <Rooms2 />
            <Newslatter />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default RoomPage;