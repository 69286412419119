import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar';
import Footer from '../../components/footer';
import Logo from '../../images/logo2.png'
import Rooms from './rooms';
import RoomDetails from './RoomDetails';
import Newslatter from '../../components/Newslatter/Newslatter';
import { formatDate } from '../../utils';
import CustomLoader from '../../components/CustomLoader';


const RoomSinglePage = (props) => {
    const { id } = useParams()
    const push = useNavigate();

    const [loading, setLoading] = useState(false);
    const [adDetails, setAdDetails] = useState({});

    useEffect(() => {
        window.scroll(0, 0);
        setLoading(true)
        let customId = null;
        let listUrl = null;
        if (id !== null) {
            customId = id
            listUrl = '/ad/' + customId;
        }
        if (props.hasOwnProperty('id')) {
            customId = props.id
            listUrl = '/ad/all/' + customId
        }
        if (customId !== null) {
            fetch(process.env.REACT_APP_API_BASE_URL + listUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        setLoading(false);
                        push("/")
                        throw new Error('Server Error');
                    }
                    return response.json();
                })
                .then(data => {
                    setAdDetails(data?.data?.length > 0 ? data.data[0] : {});
                    setLoading(false);
                })
                .catch(error => {
                    console.error('There was a problem with the fetching Ads', error);
                    push("/")
                    setLoading(false);
                });
        }
    }, []);

    return (
        <Fragment>
            <CustomLoader show={loading} />
            {!props.hasOwnProperty('id') ?
                <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
                : null}
            {/* <PageTitle pageTitle={item ? item.title : null} pagesub={'Room'} /> */}
            <div className="room-details-section">
                {adDetails && !loading ?
                    <div className="room-details-inner">
                        <div className="wpo-hotel-details-section">
                            <div className="container">
                                <div className="row justify-center">
                                    <div className='text-align-center pb-20 alert-heading justify-center display-flex'>
                                        <h5 className='color-red'><i className="fa fa-exclamation-triangle pr-10" aria-hidden="true"></i>Never pay booking amount without visiting the property</h5>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="wpo-hotel-details-wrap">
                                            <div className="wpo-hotel-details-area">
                                                <form className="clearfix">
                                                    <div className="details-sub">
                                                        <span>BEDS</span>
                                                        <h2>{adDetails?.totalRoom} Double Bed</h2>
                                                    </div>
                                                    <div className="details-sub">
                                                        <span>Currently Staying</span>
                                                        <h2>{adDetails?.totalOccupants} People</h2>
                                                    </div>
                                                    <div className="details-sub">
                                                        <span>Bathroom</span>
                                                        <h2>{adDetails?.totalBathroom} bath</h2>
                                                    </div>
                                                    <div className="details-sub">
                                                        <span>Start Rental Date</span>
                                                        <h2>{adDetails && adDetails.startRentalDate ? formatDate(adDetails.startRentalDate) : null}</h2>
                                                    </div>
                                                    <div className="details-sub">
                                                        <span>End Rental Date</span>
                                                        <h2>{adDetails ? adDetails.endRentalDate === null || adDetails.endRentalDate === undefined ? 'Not Specified' : formatDate(adDetails.endRentalDate) : null}</h2>
                                                    </div>
                                                    <div className="details-sub">
                                                        <h5>Price <span>€{adDetails?.dailyRent}</span> / Day</h5>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Rooms adDetails={adDetails} />
                        <RoomDetails adDetails={adDetails} />
                    </div>
                    : null}
            </div>
            {!props.hasOwnProperty('id') ?
                <>
                    <Newslatter nClass={'section-bg'} />
                    <Footer />
                    <Scrollbar />
                </>
                : null}
        </Fragment>
    )
};

export default RoomSinglePage;
