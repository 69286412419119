import React, { Fragment, useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import './style.scss';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../store/actions/authActions';
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';

const ChangePassword = (props) => {

    const push = useNavigate()
    const dispatch = useDispatch();

    const [value, setValue] = useState({
        password: '',
        confirm_password: ''
    });
    const [token, setToken] = useState('');

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = (e) => {
        e.preventDefault();
        if (validator.allValid() && token) {
            dispatch(changePassword(value, token));
            setValue({
                password: '',
                confirm_password: ''
            });
            validator.hideMessages();
            push('/login');
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };

    useEffect(() => {
        const search = window.location.search;
        let param = new URLSearchParams(search);
        const token = param.get('token');
        if (token) {
            setToken(token);
        } else {
            push('/');
        }
    }, [])

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <Grid className="forgetWrapper">
                <Grid className="loginForm">
                    <h2>Change Password</h2>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    type="password"
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Password"
                                    value={value.password}
                                    variant="outlined"
                                    name="password"
                                    label="Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('password', value.password, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="password"
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Confirm Password"
                                    value={value.confirm_password}
                                    variant="outlined"
                                    name="confirm_password"
                                    label="Confirm Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('confirm password', value.confirm_password, `in:${value.password}`)}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formFooter">
                                    <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Change
                                        Password</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <div className="shape-img">
                        <i className="fi flaticon-honeycomb"></i>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default ChangePassword;