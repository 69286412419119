import React, { useEffect, useState } from "react";
import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import './style.scss';
import "./file-block";
registerCoreBlocks();

const CustomTypeForm = ({ submitForm, cities, countries }) => {
    const [cityList, setCityList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    useEffect(() => {
        if (cities && cities.length > 0) {
            const updatedArray = cities.map(item => ({ label: item, value: item }));
            setCityList(updatedArray);
        }
    }, [cities])

    useEffect(() => {
        if (countries) {
            setCountryList({ label: countries, value: countries });
        }
    }, [countries])

    const rentalOptionValue = useFieldAnswer("rentalOption");

    const validateEircode = (value) => {
        // Regex for validating Eircode
        const eircodePattern = /^[AC-FHKNPRTV-Y]\d{2} ?[0-9AC-FHKNPRTV-Y]{4}$/i;
        return eircodePattern.test(value);
    };

    return (
        <>
            {countryList && cities && cities.length > 0 ?
                <div className="dynamic-height">
                    <Form
                        formId="1"
                        formObj={{
                            blocks: [
                                {
                                    name: "welcome-screen",
                                    id: "welcome-screen",
                                    attributes: {
                                        label: "Become a Host",
                                        description: "",
                                        // attachment: {
                                        //     type: "image",
                                        //     url:
                                        //         "https://quillforms.com/wp-content/uploads/2022/01/4207-ai-1.jpeg"
                                        // }
                                    }
                                },
                                // Address
                                {
                                    name: "short-text",
                                    id: "address",
                                    attributes: {
                                        classnames: "first-block",
                                        required: true,
                                        label: "Let's start with the address of the house?"
                                    }
                                },
                                // Country
                                {
                                    name: "dropdown",
                                    id: "country",
                                    attributes: {
                                        choices: [countryList],
                                        label: "Which country is the house located in?",
                                        required: true
                                    }
                                },
                                // City
                                {
                                    name: "dropdown",
                                    id: "city",
                                    attributes: {
                                        choices: cityList,
                                        label: "Which city is the house located in?",
                                        required: true
                                    }
                                },
                                // Eircode
                                {
                                    name: "short-text",
                                    id: "eircode",
                                    attributes: {
                                        classnames: "first-block",
                                        required: true,
                                        label: "Eircode?"
                                    }
                                },
                                // Phone Number
                                {
                                    name: "number",
                                    id: "phone",
                                    attributes: {
                                        "max": 999999999999,
                                        require: true,
                                        label: "What is your phone number? (please start with 353 avoid using +)"
                                    }
                                },
                                // Rental Option
                                {
                                    name: "dropdown",
                                    id: "rentalOption",
                                    attributes: {
                                        choices: [
                                            {
                                                label: "Permanent",
                                                value: "permanent"
                                            },
                                            {
                                                label: "Temporary",
                                                value: "temporary"
                                            }
                                        ],
                                        label: "Rental Option",
                                        required: true
                                    }
                                },
                                // Bedroom
                                {
                                    name: "dropdown",
                                    id: "roomType",
                                    attributes: {
                                        choices: [
                                            {
                                                label: "Shared",
                                                value: "shared"
                                            },
                                            {
                                                label: "Private",
                                                value: "private"
                                            }
                                        ],
                                        label: "Bedroom?",
                                        required: true
                                    }
                                },
                                // Bathroom
                                {
                                    name: "dropdown",
                                    id: "bathroomType",
                                    attributes: {
                                        choices: [
                                            {
                                                label: "Shared",
                                                value: "shared"
                                            },
                                            {
                                                label: "Private",
                                                value: "private"
                                            }
                                        ],
                                        label: "Bathroom",
                                        required: true
                                    }
                                },
                                // Daily Rent
                                {
                                    name: "number",
                                    id: "dailyRent",
                                    attributes: {
                                        required: true,
                                        min: 0,
                                        label: "What is the daily rent fee for the house (in euros)?"
                                    }
                                },
                                // Deposit
                                {
                                    name: "number",
                                    id: "deposit",
                                    attributes: {
                                        min: 0,
                                        label: "Daily Deposit (If Required)?"
                                    }
                                },
                                // Max Renter
                                {
                                    name: "number",
                                    id: "maxRenter",
                                    attributes: {
                                        min: 0,
                                        label: "Maximum number of Tenants you would like?",
                                        required: true,
                                    }
                                },
                                // Total Room
                                {
                                    name: "number",
                                    id: "totalRoom",
                                    attributes: {
                                        min: 0,
                                        label: "Total Bedrooms?",
                                        required: true,
                                    }
                                },
                                // Total Bathroom
                                {
                                    name: "number",
                                    id: "totalBathroom",
                                    attributes: {
                                        min: 0,
                                        label: "Total Bathroom?",
                                        required: true,
                                    }
                                },
                                // Total Occupants
                                {
                                    name: "number",
                                    id: "totalOccupants",
                                    attributes: {
                                        min: 0,
                                        label: "How many occupants are currently staying?",
                                        required: true,
                                    }
                                },
                                // Food Preference
                                {
                                    name: "dropdown",
                                    id: "foodpreference",
                                    attributes: {
                                        choices: [
                                            {
                                                label: "Vegeterian",
                                                value: "vegeterian"
                                            },
                                            {
                                                label: "Any",
                                                value: "any"
                                            }
                                        ],
                                        label: "Food Preference (If Any)"
                                    }
                                },
                                // Gender Preference
                                {
                                    name: "dropdown",
                                    id: "genderPreference",
                                    attributes: {
                                        choices: [
                                            {
                                                label: "Male",
                                                value: "male"
                                            },
                                            {
                                                label: "Female",
                                                value: "female"
                                            },
                                            {
                                                label: "Any",
                                                value: "any"
                                            }
                                        ],
                                        label: "Gender Preference?",
                                        required: true,
                                    }
                                },
                                // Start date
                                {
                                    name: "date",
                                    id: "startRentalDate",
                                    attributes: {
                                        "format": "DDMMYYYY",
                                        "separator": "/",
                                        required: true,
                                        label: "Start Rental Date"
                                    },
                                },
                                // End Date
                                ...(rentalOptionValue === 'temporary'
                                    ? [
                                        {
                                            name: "date",
                                            id: "endRentalDate",
                                            attributes: {
                                                "format": "DDMMYYYY",
                                                "separator": "/",
                                                required: true,
                                                label: "End Rental Date",
                                            },
                                        }
                                    ]
                                    : []),
                                // End Date
                                // {
                                //     name: "date",
                                //     id: "endRentalDate",
                                //     attributes: {
                                //         "format": "DDMMYYYY",
                                //         "separator": "/",
                                //         required: true,
                                //         label: "End Rental Date",
                                //     },
                                // },
                                // About House
                                {
                                    name: "long-text",
                                    id: "aboutProperty",
                                    attributes: {
                                        classnames: "first-block",
                                        required: true,
                                        label: "Tell us more about the house?"
                                    }
                                },
                                // transportation & store
                                {
                                    name: "multiple-choice",
                                    id: "withInDistance",
                                    attributes: {
                                        multiple: true,
                                        verticalAlign: false,
                                        label: "Transportations & Stores options near the house?",
                                        choices: [
                                            {
                                                label: "Lidl",
                                                value: "lidl"
                                            },
                                            {
                                                label: "Tesco",
                                                value: "tesco"
                                            },
                                            {
                                                label: "Aldi",
                                                value: "aldi"
                                            },
                                            {
                                                label: "Bus Stop",
                                                value: "busStop"
                                            },
                                            {
                                                label: "Luas",
                                                value: "luas"
                                            },
                                            {
                                                label: "Train",
                                                value: "train"
                                            },
                                            {
                                                label: "Centra",
                                                value: "centra"
                                            },
                                            {
                                                label: "Circle K",
                                                value: "circleK"
                                            },
                                            {
                                                label: "Super Value",
                                                value: "superValue"
                                            },
                                            {
                                                label: "Dealz",
                                                value: "dealz"
                                            },
                                            {
                                                label: "Indian Store",
                                                value: "indianStore"
                                            },
                                            {
                                                label: "Spar",
                                                value: "spar"
                                            }
                                        ]
                                    }
                                },
                                // Things included
                                {
                                    name: "multiple-choice",
                                    id: "amenitiesList",
                                    attributes: {
                                        multiple: true,
                                        verticalAlign: false,
                                        label: "Please choose Amenities Included in Rent?",
                                        choices: [
                                            {
                                                label: "Electricity",
                                                value: "electricity"
                                            },
                                            {
                                                label: "Television",
                                                value: "television"
                                            },
                                            {
                                                label: "Wifi",
                                                value: "wifi"
                                            },
                                            {
                                                label: "Heater",
                                                value: "heater"
                                            },
                                            {
                                                label: "Parking",
                                                value: "parking"
                                            },
                                            {
                                                label: "Bins",
                                                value: "bins"
                                            }
                                        ]
                                    }
                                },
                                // About Owner
                                {
                                    name: "long-text",
                                    id: "aboutOwner",
                                    attributes: {
                                        classnames: "first-block",
                                        required: true,
                                        label: "Tell us about the Yourself?"
                                    }
                                },
                                // Other Specification
                                {
                                    name: "long-text",
                                    id: "otherSpecification",
                                    attributes: {
                                        classnames: "first-block",
                                        label: "Are there any specific requirements for renting the house?"
                                    }
                                },
                                // Image
                                {
                                    name: "file-upload",
                                    id: "images",
                                    attributes: {
                                        label: "Please upload an house images",
                                        required: true,
                                        allowedFileExtensions: "jpg, jpeg, png",
                                        maxFileSize: 3
                                    },
                                },
                                // How did you hear
                                {
                                    name: "dropdown",
                                    id: "reference",
                                    attributes: {
                                        choices: [
                                            {
                                                label: "Instagram",
                                                value: "instagram"
                                            },
                                            {
                                                label: "Facebook",
                                                value: "facebook"
                                            },
                                            {
                                                label: "Search Engine",
                                                value: "searchEngine"
                                            },
                                            {
                                                label: "Other",
                                                value: "other"
                                            }
                                        ],
                                        label: "How did you hear about us?",
                                        required: true,
                                    }
                                },
                            ],
                            theme: {
                                font: "Muli",
                                buttonsBgColor: "#db5461",
                                answersColor: "#db5461",
                                buttonsFontColor: "#fff",
                            },
                            settings: {
                                // disableProgressBar?: boolean; // Default: false
                                // disableWheelSwiping?: boolean; // Default: false
                                // disableNavigationArrows?: boolean; // Default: false
                                animationDirection: 'horizontal',
                                // showQuestionsNumbers?: boolean; // Default: true
                                showLettersOnAnswers: false // Default: true

                            }
                        }}
                        beforeGoingNext={({
                            setIsFieldValid,
                            setIsPending,
                            currentBlockId,
                            answers,
                            setFieldValidationErr,
                            setIsCurrentBlockSafeToSwipe,
                            goToField,
                            goNext
                        }) => {
                            if (
                                currentBlockId === "endRentalDate" &&
                                answers[currentBlockId].value < answers["startRentalDate"].value
                            ) {
                                setIsFieldValid(currentBlockId, false);
                                setFieldValidationErr(currentBlockId, "End Date should be greater than Start Date");
                                setIsCurrentBlockSafeToSwipe(false);
                            } else if (currentBlockId === "phone" && answers[currentBlockId].value.toString().length !== 12) {
                                setIsFieldValid(currentBlockId, false);
                                setFieldValidationErr(currentBlockId, "Phone Number is invalid");
                                setIsCurrentBlockSafeToSwipe(false);
                            } else if (currentBlockId === "reference" && (answers[currentBlockId].value === null || answers[currentBlockId].value === undefined)) {
                                setIsFieldValid(currentBlockId, false);
                                setFieldValidationErr(currentBlockId, "Please select a option");
                                setIsCurrentBlockSafeToSwipe(false);
                            } else if (currentBlockId === "eircode" && !validateEircode(answers[currentBlockId].value)) {
                                setIsFieldValid(currentBlockId, false);
                                setFieldValidationErr(currentBlockId, "Please enter valid eircode");
                                setIsCurrentBlockSafeToSwipe(false);
                            } else if (currentBlockId === "startRentalDate") {
                                const dateString = answers[currentBlockId].value;
                                const parts = dateString.split("/");
                                const day = parts[0];
                                const month = parts[1];
                                const year = parts[2];
                                const date = new Date(year, month - 1, day);
                                if (date < new Date()) {
                                    setIsFieldValid(currentBlockId, false);
                                    setFieldValidationErr(currentBlockId, "Start Rental Date is invalid");
                                    setIsCurrentBlockSafeToSwipe(false);
                                } else {
                                    setIsFieldValid(currentBlockId, true);
                                    setFieldValidationErr(currentBlockId, "");
                                    setIsCurrentBlockSafeToSwipe(true);
                                    goNext();
                                }
                            } else {
                                setIsFieldValid(currentBlockId, true);
                                setFieldValidationErr(currentBlockId, "");
                                setIsCurrentBlockSafeToSwipe(true);
                                goNext();
                            }
                        }}
                        onSubmit={(data, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
                            setTimeout(() => {
                                if (localStorage.getItem('token')) {
                                    setIsSubmitting(false);
                                    submitForm(data?.answers);
                                    // completeForm();
                                } else {
                                    setSubmissionErr("Please login before submitting the form")
                                }
                            }, 500);
                        }}
                    />
                </div> : null}
        </>
    )
}

export default CustomTypeForm;