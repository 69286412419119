import React from 'react';
// import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';


const GoogleButton = ({ onSuccess, onFailure, page }) => {

    const login = useGoogleLogin({
        onSuccess: tokenResponse => onSuccess(tokenResponse),
        onError: (error) => onFailure('Login Failed:', error)
    });

    return (
        <div className="google w-100">
            {/* <GoogleLogin
                clientId="653429070791-flk0k2j22v6jodb2v2m145ett30ej8u1.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
            /> */}
            {/* <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure} /> */}
            <button onClick={() => login()}>{page ? 'Sign in' : 'Sign up'} with Google</button>
        </div>
    );
};

export default GoogleButton;