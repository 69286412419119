import React from 'react';
import './style.scss';

const CustomLoader = ({ show }) => {
    if (!show) {
        return null; // If show is false, don't render anything
    }
    return (
        <div className="loader">
            <div className="house-icon"></div>
            <div className="magnifying-glass"></div>
        </div>
    );
};

export default CustomLoader;
