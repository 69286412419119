import React, { useState, Fragment } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';
import GoogleButton from '../GoogleLogin';
import { useDispatch } from 'react-redux';
import { signIn, googleSignUp } from '../../store/actions/authActions';
import { Helmet } from "react-helmet";
import CustomLoader from '../../components/CustomLoader';
import './style.scss';
import axios from 'axios';

const LoginPage = (props) => {

    const push = useNavigate()
    const dispatch = useDispatch();

    const [value, setValue] = useState({
        email: '',
        password: '',
        remember: false,
    });
    const [user, setUser] = useState(null);
    const [isCompleted, setCompleted] = useState(false);

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({ ...value, remember: !value.remember });
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = (e) => {
        e.preventDefault();
        setCompleted(true);
        if (validator.allValid()) {
            validator.hideMessages();
            dispatch(signIn(value, push, setCompleted));
        } else {
            setCompleted(false);
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };

    const handleLoginSuccess = (response) => {
        setCompleted(true);
        if (response && response.access_token) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${response.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    let user = res.data;
                    if (user?.verified_email) {
                        let credentials = {
                            email: user?.email,
                            name: user?.given_name,
                            lastname: user?.family_name
                        }
                        setValue({
                            ...value,
                            email: user?.email,
                            name: user?.given_name,
                            lastname: user?.family_name,
                            remember: !value.remember
                        });
                        dispatch(googleSignUp(credentials, push, setCompleted));
                    } else {
                        toast.error('Email isn`t verified');
                        setCompleted(false);
                    }
                })
                .catch((err) => {
                    setCompleted(false);
                    toast.error('Please try again after sometime');
                    console.log(err);
                });
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Login failed:', error);
        toast.error('Please try again after sometime');
    };

    const handleLogout = () => {
        setUser(null);
    };

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login</title>
                <link rel="canonical" href="https://toletout.ie/login" />
                <meta name='description' content='Your journey begins with a simple click. Login today.' />
                <meta property="og:title" content="Login" />
                <meta property="og:description" content="Your journey begins with a simple click. Login today." />
                <meta name="twitter:title" content="Login" />
                <meta name="twitter:description" content="Your journey begins with a simple click. Login today." />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <CustomLoader show={isCompleted} />
            <Grid className="loginWrapper">
                <Grid className="loginForm">
                    <h2>Sign In</h2>
                    <p>Sign in to your account</p>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="E-mail"
                                    value={value.email}
                                    variant="outlined"
                                    name="email"
                                    label="E-mail"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('email', value.email, 'required|email')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Password"
                                    value={value.password}
                                    variant="outlined"
                                    name="password"
                                    type="password"
                                    label="Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('password', value.password, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formAction">
                                    {/* <FormControlLabel
                                        control={<Checkbox checked={value.remember} onChange={rememberHandler} />}
                                        label="Remember Me"
                                    /> */}
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </Grid>
                                <Grid className="formFooter">
                                    <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <hr className="solid" />
                    <Grid className="loginWithSocial">
                        <GoogleButton onSuccess={handleLoginSuccess} onFailure={handleLoginFailure} page={true} />
                        {/* <Button className="google"><i className="fa fa-google"></i></Button>
                                    <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                    <Button className="linkedin"><i className="fa fa-linkedin"></i></Button> */}
                    </Grid>
                    <p className="noteHelp">Don't have an account? <Link to="/register">Create free account</Link>
                    </p>
                    <div className="shape-img">
                        <i className="fi flaticon-honeycomb"></i>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default LoginPage;