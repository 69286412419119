import React, { Fragment, useRef } from 'react';
import { Helmet } from "react-helmet";
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo2.png'
import Newslatter from '../../components/Newslatter/Newslatter';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './style.scss'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const FAQs = () => {
    const panelRefs = useRef({});
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        const panelElement = panelRefs.current[panel];
        if (newExpanded && panelElement) {
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const viewportCenter = windowHeight / 2;
            const clickY = event.clientY;
            const rect = panelElement.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const panelTop = clickY < viewportCenter ? rect.top + scrollTop : scrollTop;
            // const panelTop = scrollTop;
            const scrollPosition = panelTop - (windowHeight / 2) + (rect.height / 2) - 100;
            window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
        }
    }

    const accordionStyles = {
        width: '80%',
        margin: '0 auto',
    };
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQs</title>
                <link rel="canonical" href="https://toletout.ie/faq" />
                <meta name='description' content='Navigate through our FAQs page for quick answers to common queries. Whether you are a new visitor or a seasoned user, find solutions to your questions effortlessly.' />
                <meta property="og:title" content="FAQs" />
                <meta property="og:description" content="Navigate through our FAQs page for quick answers to common queries. Whether you are a new visitor or a seasoned user, find solutions to your questions effortlessly." />
                <meta name="twitter:title" content="FAQs" />
                <meta name="twitter:description" content="Navigate through our FAQs page for quick answers to common queries. Whether you are a new visitor or a seasoned user, find solutions to your questions effortlessly." />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={'FAQ'} pagesub={'FAQ'} pageHeaderImage='wpo-faq-area' />
            {/* <h1 className='text-center f-s-50 pt-50'>FAQ's</h1> */}
            <div style={accordionStyles} className='pt-50 pb-50 faq-page'>
                <div className='accordion-wrapper'>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>What type of accommodation does we offer?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel1'] = el)}>
                            {/* <Typography> */}
                            <h5>We provide a range of accommodation options with an emphasis on superior comfort and privacy.</h5>
                            <p>Here are the 3 accommodation types we offer:</p>
                            <ul>
                                <li><b>Private Room with Shared Bathroom: </b>
                                    When you book a room with Hosting Power, you'll have your own fully furnished private bedroom
                                    which has a comfortable single or double bed. You will share the other rooms with your host.</li>

                                <li><b>Private Room with Private Bathroom: </b>
                                    Additionally to your private room, you'll get your own private bathroom for your sole use.
                                    Ideal for someone who wants a little more privacy! You will share the other rooms, such as the kitchen, with your host.</li>

                                <li><b>Private Studio: </b>
                                    Our Studios are completely self-contained, including a fully private bathroom and kitchen.
                                    No space to share with anyone!</li>
                            </ul>
                            {/* </Typography> */}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>How do I know if the accommodation listed is legit?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel2'] = el)}>
                            <Typography>
                                To ensure the legitimacy of listed accommodations, we follow a verification process.
                                Hosts must complete their profiles, provide valid identification, and undergo reference checks.
                                We personally verify each and every listing to ensure they are legit.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography>H​ow long can I book a room for?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel3'] = el)}>
                            <Typography>
                                You can book your room for any duration based on the start and end date mentioned by the Host.
                                We highly advise that you book for the entire period of your visit.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography>Are bills included in the rent?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel4'] = el)}>
                            <Typography>
                                Every listing will have details of what kind of bills are included. Most listing will have all
                                bills included.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography>Who are the host(s) I will be sharing with ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel5'] = el)}>
                            <Typography>
                                Your host(s), the majority of whom are the home's owners, will be sharing the property with you.
                                Although each host comes from a different background, they all have the same commitment to making you
                                feel welcome.
                                Please look at the host description on each listing to learn more specific details about each host!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                            <Typography>Is it possible to work or study from home?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel6'] = el)}>
                            <Typography>
                                We can guarantee you that this typically won't be an issue, yes.
                                Many of our visitors are now doing their work or studying at home.
                                It's crucial to include on your booking request whether you're willing to work from home or take classes from there so
                                that your host is aware of this information before accepting your reservation.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                            <Typography>How much will the service fee charged to me?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel7'] = el)}>
                            <Typography>
                                Nothing, Its completely free service.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                            <Typography>How will I pay my rent?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel8'] = el)}>
                            <Typography>
                                The method of payment for rent is in cash which is to be handed over directly to owner.
                                We do not take any charges from you.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                            <Typography>When can I contact my host?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel9'] = el)}>
                            <Typography>
                                You will get your host's complete contact information, including their full name, full address,
                                email address, and phone number on your email
                                as soon as the host approves the viewing. you can get in touch with them right away.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                        <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                            <Typography>Why would my request be declined?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel10'] = el)}>
                            <Typography>
                                The host may have neglected to notify us of a last-minute modification or may be
                                seeking for requests for a different amount of time, for example, if your booking request
                                is denied even though the room or studio is available.
                                If the host refuses your request, they may have gone ahead with other tenent.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
                            <Typography>Do I get a refund if I cancel?</Typography>
                        </AccordionSummary>
                        <AccordionDetails ref={(el) => (panelRefs.current['panel11'] = el)}>
                            <Typography>
                                If the Host have approved your viewing and you make the payment to Host than you would need to
                                request the Host for refund, we won't be able to help you. Always be sure before making any payment.
                                You can always call or email us regarding your concern and we will definitely help you out.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
            <Newslatter nClass={'section-bg'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default FAQs;

