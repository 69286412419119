import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { formatDate } from '../../utils';
import CustomPopup from '../CustomPopup';
import './style.scss';

const CustomTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState(props.rows || []);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Custom sorting function
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    // Function to get sorting order
    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
            : (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
    };

    // Function to compare values for descending sorting
    const descendingComparator = (a, b) => {
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;
    };

    useEffect(() => {
        setRows(props.rows);
    }, [props.rows]);

    return (
        <Paper>
            <TableContainer>
                <CustomPopup msg="Are you sure you want to remove this lsiting ?" open={props.openPopup} save={props.deleteListing} close={props.handleClose} showButtons={true} />
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {props.columns.map((column) => (
                                <TableCell
                                    className="truncate-text text-align-left"
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        <b>{column.label}</b>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow key={row._id}>
                                    {/* <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.age}</TableCell> */}
                                    {props.columns.map((column) => (
                                        <TableCell key={column.id} className="truncate-text text-align-left">
                                            {column.id === 'isVerified' ?
                                                row[column.id] === false ?
                                                    'No' : 'Yes' :
                                                column.id === 'startRentalDate' ?
                                                    formatDate(row[column.id]) :
                                                    column.id === 'endRentalDate' ?
                                                        row['endRentalDate'] === null || row['endRentalDate'] === undefined ? 'Not Specified' : formatDate(row[column.id]) :
                                                        column.id === 'edit' ? row['isVerified'] === false ?
                                                            <>
                                                                {/* <i className="fa fa-edit cursor-pointer pd-10 hover-effect" onClick={() => props.editListing(row['_id'])} aria-hidden="true"></i> */}
                                                                <i className="fa fa-trash cursor-pointer pd-10 hover-effect" onClick={() => props.confirmPopup(row['_id'])} aria-hidden="true"></i>
                                                            </>
                                                            : <i className="fa fa-trash cursor-pointer pd-10 hover-effect" onClick={() => props.confirmPopup(row['_id'])} aria-hidden="true"></i> :
                                                            column.id === 'aboutProperty' ? localStorage.getItem('isAdmin') ? <span className="cursor-pointer text-align-left color-blue" onClick={() => props.showListing(row['_id'])}>{row['aboutProperty']}</span> : row['aboutProperty'] :
                                                                column.id === 'name' ? row['firstName'] + ' ' + row['lastName'] :
                                                                    column.id === 'rentalOption' ? row['rentalOption'] === 'permanent' ? 'Permanent' : 'Temporary' :
                                                                        column.id === 'deposit' ? !row.hasOwnProperty('deposit') ? 'No Deposit' : row['deposit'] :
                                                                            column.id === 'verified' ? row['isVerified'] === true ? 'true' : 'false' :
                                                                                column.id === 'email' ? row['email'] ? row['email'] : '' :
                                                                                    column.id === 'phoneno' ? row['phone'] ? row['phone'] : '' :
                                                                                        column.id === 'verificationStatus' ? !row.hasOwnProperty('verificationStatus') ? 'Pending' : row['verificationStatus'] === true ? 'Approved' : 'Rejected' :
                                                                                            column.id === 'action' ? localStorage.getItem('isAdmin') ? !row['isVerified'] ? <>
                                                                                                <i className="fa fa-check cursor-pointer pd-10 hover-effect" onClick={() => props.updateRequest(row['_id'], 'Approved')} aria-hidden="true"></i>
                                                                                                <i className="fa fa-close cursor-pointer pd-10 hover-effect" onClick={() => props.updateRequest(row['_id'], 'Rejected')} aria-hidden="true"></i>
                                                                                            </> : 'Verified' :
                                                                                                row['status'] === "Pending" ?
                                                                                                    <>
                                                                                                        <i className="fa fa-check cursor-pointer pd-10 hover-effect" onClick={() => props.updateRequest(row['_id'], 'Approved')} aria-hidden="true"></i>
                                                                                                        <i className="fa fa-close cursor-pointer pd-10 hover-effect" onClick={() => props.updateRequest(row['_id'], 'Rejected')} aria-hidden="true"></i>
                                                                                                    </>
                                                                                                    :
                                                                                                    row['status'] :
                                                                                                column.id === 'requestReceived' ? <span className='cursor-pointer color-blue' onClick={() => props.showViewingRequest(row['_id'])}>{props?.listCount?.filter((item) => item._id === row['_id']).length} </span> :
                                                                                                    row[column.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default CustomTable;