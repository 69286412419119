import React, { useState, Fragment, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';
import './style.scss';
// Tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../../components/Table';
import { removeListing } from '../../store/actions/addListingActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { updateViewingRequest } from '../../store/actions/listingRequestAction';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UserListingPage = (props) => {
    const [value, setValue] = useState(0);
    const [rows, setRows] = useState([]);
    const [listCount, setListCount] = useState([]);
    const [viewingRows, setViewingRows] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const dispatch = useDispatch();
    const push = useNavigate();

    const columns = [
        { id: 'aboutProperty', label: 'About Property' },
        { id: 'roomType', label: 'Room Type' },
        { id: 'dailyRent', label: 'Daily Rent' },
        { id: 'deposit', label: 'Deposit' },
        { id: 'rentalOption', label: 'Rental Option' },
        { id: 'startRentalDate', label: 'Start Rental Date' },
        { id: 'endRentalDate', label: 'End Rental Date' },
        { id: 'isVerified', label: 'Is Verified' },
        { id: 'requestReceived', label: 'Request Received' },
        { id: 'edit', label: 'Edit' },
    ];

    const viewingColumns = [
        { id: 'name', label: 'Name' },
        { id: 'aboutGuest', label: 'About Guest' },
        { id: 'totalGuest', label: 'Total Guest' },
        { id: 'email', label: 'Email' },
        { id: 'phoneno', label: 'Phone No.' },
        { id: 'verified', label: 'Verified' },
        { id: 'action', label: 'Action' }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Split character 
    // function splitTextAtCapitalLetters(text) {
    //     const parts = text.split(/(?=[A-Z])/);
    //     const result = parts.join(' ');
    //     return result;
    // }

    const editListing = (id) => {
        push('/addlisting/' + id);
    }

    const updateRequest = (id, status) => {
        dispatch(updateViewingRequest(id, status, push));
    }

    const confirmPopup = async (id) => {
        if (id) {
            setOpenPopup(true);
            setDeleteId(id);
        }
    };

    const handleClose = () => {
        setOpenPopup(false);
    };

    const showViewingRequest = (id) => {
        setValue(1)
        fetch(process.env.REACT_APP_API_BASE_URL + '/viewing/' + id, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server Error');
                }
                return response.json();
            })
            .then(data => {
                setViewingRows(data?.data)
            })
            .catch(error => {
                console.error('There was a problem with the fetching Ads', error);
            });
        setViewingRows(viewingRows.filter((item) => item.listId === id));
    }

    const deleteListing = async () => {
        if (deleteId) {
            dispatch(removeListing(deleteId));
            const updatedHouses = rows.filter((list) => list._id !== deleteId);
            setRows(updatedHouses);
            setOpenPopup(false);
            setDeleteId('');
        } else {
            toast.error('Server Error');
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && token !== 'undefined' && token !== undefined) {
            fetch(process.env.REACT_APP_API_BASE_URL + '/ad/user-listing', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Server Error');
                    }
                    return response.json();
                })
                .then(data => {
                    setRows(data?.data)
                    setListCount(data?.results)
                })
                .catch(error => {
                    console.error('There was a problem with the fetching Ads', error);
                });
        }
        // if (token && token !== 'undefined' && token !== undefined) {
        //     fetch(process.env.REACT_APP_API_BASE_URL + '/viewing/user-listing', {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': `Bearer ${localStorage.getItem('token')}`,
        //         },
        //     })
        //         .then(response => {
        //             if (!response.ok) {
        //                 throw new Error('Server Error');
        //             }
        //             return response.json();
        //         })
        //         .then(data => {
        //             setViewingRows(data?.data)
        //         })
        //         .catch(error => {
        //             console.error('There was a problem with the fetching Ads', error);
        //         });
        // }
    }, []);

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <Grid className="userlistingWrapper">
                <Grid className="userlistingSection">
                    <p className='note'>Note: Click on request receive count to approve/reject the viewing</p>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="My Listing" {...a11yProps(0)} />
                                <Tab label="Viewing Request" {...a11yProps(1)} disabled={true} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <CustomTable
                                rows={rows}
                                columns={columns}
                                listCount={listCount}
                                editListing={editListing}
                                deleteListing={deleteListing}
                                handleClose={handleClose}
                                confirmPopup={confirmPopup}
                                openPopup={openPopup}
                                showViewingRequest={showViewingRequest} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1} disabled>
                            <CustomTable
                                rows={viewingRows}
                                columns={viewingColumns}
                                updateRequest={updateRequest}
                                handleClose={handleClose}
                                confirmPopup={confirmPopup}
                                openPopup={openPopup} />
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default UserListingPage;