import { toast } from "react-toastify";

// Set a session cookie with a 7-day expiration (adjust as needed)
const setSessionCookie = (name, value) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 7); // Expires in 7 days
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
};

// Get a session cookie by name
// const getSessionCookie = (name) => {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
// };

export const signIn = (userData, history, setCompleted) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/users/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && responseData.status === 200) {
            setSessionCookie('sessionId', responseData.data);
            const token = responseData.data;
            const userId = responseData?.userId;
            const isAdmin = responseData?.isAdmin;

            // Store the token in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);
            if (isAdmin) {
                localStorage.setItem('isAdmin', isAdmin);
            }
            dispatch({ type: 'SIGN_IN', payload: responseData });
            setCompleted(false);
            history('/');
            toast.success('Sign-in successful!');
        } else {
            // toast.error('Sign-in failed. Please check your credentials and try again.');
            // Login failed
            setCompleted(false);
            toast.error(responseData.msg);
        }
    } catch (error) {
        // Handle network errors
        setCompleted(false);
        toast.error('Network error. Please try again.');
    }
};

export const signOut = (history) => (dispatch) => {
    // toast.success("User Logged out");
    localStorage.removeItem("token");
    localStorage.removeItem('userId');
    localStorage.removeItem('isAdmin');
    history('/');
    dispatch({
        type: 'SIGN_OUT',
    });
    toast.error('Ops, Please Login');
};

export const signUp = (userData, history, setCompleted) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/users/addUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && responseData.status === 200) {
            // Store the session ID (authentication token) in a session cookie
            // setSessionCookie('sessionId', responseData.data);
            // const { token } = responseData.data;

            // Store the token in localStorage
            // localStorage.setItem('token', token);

            dispatch({ type: 'SIGN_UP', payload: responseData });
            history('/');
            setCompleted(false);
            toast.success('Verification link sent on your email, Please verify your email address');
        } else {
            // Registration failed
            setCompleted(false);
            toast.error(responseData.msg);
        }
    } catch (error) {
        console.error('Error:', error);
        setCompleted(false);
        toast.error('Registration failed. Please try again later.');
    }
};


export const googleSignUp = (userData, history, setCompleted) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/users/googlelogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && responseData.status === 200) {
            // Store the session ID (authentication token) in a session cookie
            setSessionCookie('sessionId', responseData.data);
            const token = responseData.data;
            const userId = responseData?.userId;

            // Store the token in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('userId', userId);

            dispatch({ type: 'SIGN_IN', payload: responseData });
            setCompleted(false);
            history('/');
            toast.success('Logged-in successful!');
        } else {
            // Registration failed
            setCompleted(false);
            toast.error(responseData.msg);
        }
    } catch (error) {
        console.error('Error:', error);
        setCompleted(false);
        toast.error('Registration failed. Please try again later.');
    }
};

export const forgetPassword = (userData) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/users/sendResetLink', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && responseData.status === 200) {
            dispatch({ type: 'FORGET_PASSWORD', payload: responseData });
        } else {
            // Registration failed
            toast.error(responseData.msg);
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('Registration failed. Please try again later.');
    }
};

export const subscribe = (userData) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/subscribe/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json();

        if (response.ok && responseData.status === 200) {
            dispatch({ type: 'SUBSCRIBE', payload: responseData });
            toast.success(responseData?.msg);
        } else {
            toast.error(responseData?.msg);
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('Please try again later.');
    }
};

export const verifyPassword = (token) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/users/verify', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        const responseData = await response.json();
        if (response.ok && responseData.status === 200) {
            toast.success(responseData?.msg);
        } else {
            // Registration failed
            toast.error(responseData.msg);
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('Verification failed. Please try again later.');
    }
};

export const changePassword = (value, token) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/users/updatePassword', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(value),
        });

        const responseData = await response.json();
        if (response.ok && responseData.status === 200) {
            toast.success(responseData?.msg);
        } else {
            // Registration failed
            toast.error(responseData.msg);
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('Verification failed. Please try again later.');
    }
};
