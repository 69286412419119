import { toast } from "react-toastify";

export const userQuery = (userData, history) => async (dispatch) => {
    try {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/contact/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        await response.json();

        if (response.ok && (response.status === 200 || response.status === 201)) {
            dispatch({ type: 'USER_QUERY' });
            history('/');
            toast.success('Request Send successfully :). You will be notified once request is address.');
        } else {
            // Login failed
            toast.error(response.msg);
        }
    } catch (error) {
        // Handle network errors
        toast.error('Network error. Please try again.');
    }
};
