import React from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../sass/style.scss';
import { GoogleOAuthProvider } from "@react-oauth/google"


const App = () => {

  return (
    <div className="App" id='scrool'>
      <GoogleOAuthProvider clientId='653429070791-flk0k2j22v6jodb2v2m145ett30ej8u1.apps.googleusercontent.com'>
        <AllRoute />
        <ToastContainer />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
