import React from 'react';
import './style.scss';

const AboutUs = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-about-section section-padding">
            <div className="wpo-about-section-wrapper">
                <div className="row text-align-justify pt-10 pb-10 pr-60 pl-60">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="wpo-about-content">
                            <div className="wpo-about-content-inner">
                                <p className='f-s-18'>Welcome to ToLetOut, the ultimate destination for all your renting needs. Whether you're searching for your dream space or looking to earn extra income by listing your property, ToLetOut has you covered.
                                    Our platform connects renters and landlords seamlessly, making the renting process a breeze. Say goodbye to endless searches and complicated listings - ToLetOut simplifies it all.
                                    Join ToLetOut today and experience a new way of renting that's fun, fast, and hassle-free!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-align-justify pt-20 pb-10 pr-60 pl-60 background-light-grey">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="wpo-about-content">
                            <div className="about-title">
                                <h2>Our Mission</h2>
                            </div>
                            <div className="wpo-about-content-inner">
                                <p className=''>At To Let Out Homes, we are dedicated to redefining the rental experience.
                                    Our mission is to make renting and managing properties easy, efficient, and stress-free
                                    for everyone involved. We believe that when we connect renters with their dream homes,
                                    and property owners with reliable property management solutions,
                                    we create success stories.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-align-justify pt-20 pb-10 pr-60 pl-60">
                    <div className="wpo-about-content">
                        <div className="about-title">
                            <h2>How We Work</h2>
                        </div>
                        <div className="wpo-about-content-inner">
                            <p>We work collaboratively, using our local expertise and market insights to
                                help clients achieve their goals.
                                Our approach is client-focused, data-driven, and result-oriented.</p>
                        </div>
                    </div>
                </div>
                <div className="row text-align-justify pt-20 pb-10 pr-60 pl-60 background-light-grey">
                    <div className="wpo-about-content">
                        <div className="about-title">
                            <h2>Local Impact</h2>
                        </div>
                        <div className="wpo-about-content-inner">
                            <p> Letting Homes is proud to be part of the community. We support local initiatives, sponsor events,
                                and take part in charity work to make a positive impact on the neighborhoods we serve.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default AboutUs;