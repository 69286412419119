import React, { useState, useEffect, useRef } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import Stack from '@mui/material/Stack';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import "./style.scss";

const SearchSection = (props) => {
    const btnRef = useRef();
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [value, setValue] = useState({
        startDate: "",
        endDate: "",
        selectedCities: "",
        totalGuest: 0,
        totalRooms: 0
    });
    const push = useNavigate();

    const [searchActive, setsearchState] = useState(false);


    const SubmitHandler = (e) => {
        e.preventDefault();
        push("/search-result", { state: { value } });
    }

    const adultincrementCount = () => {
        // setCount(adult + 1);
        setValue({ ...value, totalGuest: value.totalGuest + 1 });
    };
    const adultdecrementCount = () => {
        // setCount(adult - 1);
        setValue({ ...value, totalGuest: value.totalGuest - 1 });
    };

    const roomincrementCount = () => {
        // setroom(room + 1);
        setValue({ ...value, totalRooms: value.totalRooms + 1 });
    };
    const roomdecrementCount = () => {
        // setroom(room - 1);
        setValue({ ...value, totalRooms: value.totalRooms - 1 });
    };

    // Fetch the country list
    const fetchCountries = async () => {
        try {
            const response = await fetch("https://countriesnow.space/api/v0.1/countries");
            if (response.ok) {
                const data = await response.json();
                setCountries(data.data);
                const selectedCountry = data?.data.find((c) => c.country === "Ireland");
                if (selectedCountry.hasOwnProperty('cities')) {
                    setCities(selectedCountry.cities);
                }
            } else {
                console.error("Failed to fetch countries data");
            }
        } catch (error) {
            console.error("Error fetching countries data: ", error);
        }
    };

    // fetch Cities
    // const fetchCities = (country) => {
    //     const selectedCountry = countries.find((c) => c.country === country);
    //     setCities(selectedCountry?.cities || []);
    // };

    const handleCityChange = (event, newValue) => {
        if (newValue) {
            setValue({ ...value, selectedCities: newValue });
        } else {
            setValue({ ...value, selectedCities: newValue });
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (props && props.value) {
            setValue(props.value || {})
        }
    }, [props.value])

    useEffect(() => {
        const closeDropdown = e => {
            if (!btnRef.current.contains(e.target)) {
                setsearchState(false);
            }
        }
        document.body.addEventListener('click', closeDropdown)
        return () => document.body.removeEventListener('click', closeDropdown)
    }, []);

    return (
        <div className={`wpo-select-section ${props.svClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-select-wrap">
                            <div className="wpo-select-area">
                                <form className="clearfix" onSubmit={SubmitHandler}>
                                    <div className="select-sub">
                                        <div className="input-group date">
                                            <DatePicker
                                                id="start-date"
                                                placeholderText="Start Date"
                                                label="Start Date"
                                                selected={value?.startDate}
                                                minDate={new Date()}
                                                onChange={(date) => setValue({
                                                    ...value, startDate: date
                                                })} />
                                            <i className="fi flaticon-calendar"></i>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <div className="input-group date">
                                            <DatePicker label="End Date"
                                                placeholderText="End Date"
                                                selected={value?.endDate}
                                                minDate={value?.startDate || new Date()}
                                                onChange={(date) => setValue({
                                                    ...value, endDate: date
                                                })} />
                                            <i className="fi flaticon-calendar"></i>
                                        </div>
                                    </div>
                                    <div className="select-sub flex-basis-25">
                                        <div className="city">
                                            <Autocomplete
                                                // multiple
                                                // limitTags={2}
                                                className='pl-10 f-s-16'
                                                id="tags-standard"
                                                options={cities}
                                                value={value.selectedCities || null}
                                                onChange={handleCityChange}
                                                // onInputChange={(event, newInputValue) => {
                                                //     fetchCityOptions(newInputValue);
                                                // }}
                                                getOptionLabel={(option) => option}
                                                // defaultValue={}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="City"
                                                        placeholder="City"
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="select-sub" ref={btnRef}>
                                        <div className="form-group tourist-group">
                                            <div className="tourist-group-wrap">
                                                <div className="tourist-inner" onClick={() => setsearchState(!searchActive)}>
                                                    <i className="fi flaticon-user"></i>
                                                    {/* <ul>
                                                        <li><input disabled type="text" id="adults" value={adult} />Adults
                                                        </li>
                                                        <li><input disabled type="text" id="children" value={child} />
                                                            Children</li>
                                                        <li><input disabled type="text" id="rooms" value={room} /> Room</li>
                                                    </ul> */}
                                                    <label>Guest</label>
                                                    <i className={`ti-angle-down ${searchActive ? "rotate" : ""}`}></i>
                                                </div>
                                                <div className={`tourist-dropdown ${searchActive ? "active" : ""}`}>
                                                    <div className="tourist-item">
                                                        <span>Guest</span>
                                                        <div className="tourist-item-group">
                                                            <button type="button" disabled={value.totalGuest < 1} onClick={adultdecrementCount} id="adults_dec">-</button>
                                                            <input disabled id="adults_val" value={value?.totalGuest} type="text" />
                                                            <button type="button" onClick={adultincrementCount} id="adults_inc">+</button>
                                                        </div>
                                                    </div>
                                                    <div className="tourist-item">
                                                        <span>Rooms</span>
                                                        <div className="tourist-item-group">
                                                            <button type="button" disabled={value.totalRooms < 1} onClick={roomdecrementCount} id="rooms_dec">-</button>
                                                            <input disabled id="rooms_val" value={value?.totalRooms} type="text" />
                                                            <button type="button" onClick={roomincrementCount} id="rooms_inc">+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <Button className="theme-btn">Check Availability</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SearchSection;

