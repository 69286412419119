import React, { useState } from 'react';
import Shape1 from '../../images/nshape1.png'
import Shape2 from '../../images/nshape2.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { subscribe } from '../../store/actions/authActions';

const Newslatter = (props) => {

    const push = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = useState({
        email: '',
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    };

    const SubmitHandler = (e) => {
        e.preventDefault()
        dispatch(subscribe(value));
        setValue({
            email: '',
        });
        push('/');
    }


    return (
        <section className={`wpo-newslatter-section section-padding ${props.nClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7">
                        <div className="wpo-section-title-s2">
                            <h2>Subscribe & Get Notified</h2>
                            <p>Don't Want to Miss Anything? Subscribe Now To Be Notified Of New Listings</p>
                        </div>
                    </div>
                </div>
                <div className="wpo-newsletter">
                    <div className="newsletter-form">
                        <form onSubmit={SubmitHandler}>
                            <input type="email" className="form-control"
                                name='email'
                                value={value?.email || ''}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                                placeholder="Enter Your Email Address..." required />
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="n-shape">
                <img src={Shape1} alt="" />
            </div>
            <div className="n-shape2">
                <img src={Shape2} alt="" />
            </div>
        </section>
    )
}


export default Newslatter;