import React, { useState, useEffect, useRef } from 'react';
import { Map, GoogleApiWrapper, Circle } from 'google-maps-react';

const MapContainer = ({ google, customEircode }) => {
    const [coordinates, setCoordinates] = useState({
        lat: 0, // Latitude
        lng: 0, // Longitude
    });
    const mapContainerRef = useRef(null);
    const [dimension, setDemension] = useState({
        width: "0px",
        height: "0px"
    })
    const radius = 500;

    useEffect(() => {
        // Replace 'YOUR_API_KEY' with your actual Google Maps API key
        const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

        // Replace 'YOUR_EIRCODE' with the Eircode you want to plot
        const eircode = customEircode;

        // Use the Geocoding API to get the coordinates
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${eircode}&key=${API_KEY}`
        )
            .then((response) => response.json())
            .then((data) => {
                const location = data?.results[0]?.geometry.location;
                setCoordinates({
                    lat: location?.lat,
                    lng: location?.lng,
                });
            });
    }, [customEircode]);

    useEffect(() => {
        // Update the map size when the component mounts and when it resizes
        const updateMapSize = () => {
            const container = mapContainerRef.current;
            const width = container.clientWidth;
            const height = 400;

            container.style.height = `${height}px`;
            container.style.width = `${width}px`;
            setDemension({
                width: container.style.width,
                height: container.style.height
            })
        };

        updateMapSize();
        window.addEventListener('resize', updateMapSize);

        return () => {
            window.removeEventListener('resize', updateMapSize);
        };
    }, []);

    const { lat, lng } = coordinates;

    return (
        <div ref={mapContainerRef} >
            {lat !== 0 || lng !== 0 ? <Map
                google={google}
                initialCenter={{ lat, lng }}
                zoom={14}
                style={{ width: dimension.width, height: dimension.height }}
            >
                {/* <Marker position={{ lat, lng }} /> */}
                <Circle
                    center={coordinates}
                    radius={radius}
                    strokeColor="#ff0000"
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor="#ff0000"
                    fillOpacity={0.35}
                />
            </Map> : null}
        </div>
    );
};

export default GoogleApiWrapper(
    (props) => ({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: props.language,
    }))(MapContainer);
