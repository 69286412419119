import React, { useContext } from "react";
import {
  IconButton,
  Grid
} from '@mui/material';
import "./style.scss";
import { ImageContext } from '../../../main-component/AddListing/ImageContext';

const FileBlockDisplay = () => {
  const { images, handleImageChange, handleRemoveImage } = useContext(ImageContext);
  return (
    <div className="question__wrapper">
      {handleImageChange && (
        <div style={{ marginTop: '20px' }}>
          <input type="file" multiple onChange={handleImageChange} />
        </div>
      )}
      {/* Render list of uploaded files */}
      {images.length > 0 && (
        <div>
          <h4>Uploaded Files:</h4>
          <div className="display-flex">
            {images.map((image, index) => (
              <Grid item key={index}>
                <div className="image-container position-relative">
                  <img
                    src={image}
                    alt={`Image ${index}`}
                  // style={{ maxWidth: "100px" }}
                  />
                  <IconButton
                    size="small"
                    color="secondary"
                    className="position-absolute background-white rt-0"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <i className="fa fa-close color-black" />
                  </IconButton>
                </div>
              </Grid>
            ))}
          </div>
          {/* {files.map((file, index) => (
              <li key={index}>
                {file.name} - {file.size} bytes
                <button onClick={() => removeFile(index)}>Remove</button>
              </li>
            ))} */}
        </div>
      )}
    </div>
  );
};
export default FileBlockDisplay;
