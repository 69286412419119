import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SectionTitleS2 from '../SectionTitleS2'
import { eirCodeDecode } from "../../utils/eircodeDecode";
import {
  Select,
  MenuItem,
} from '@mui/material';

const SearchRooms = ({ rooms, sortBy, setSortBy }) => {
  const push = useNavigate();
  const [roomList, setRooms] = useState(rooms || []);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const redirectTo = (id) => {
    window.scrollTo(10, 0);
    push('/details/' + id);
  }

  const titleStyle = {
    textTransform: 'capitalize',
  };

  const changeHandler = (e) => {
    setSortBy(e.target.value || 0)
    sortProducts(e.target.value);
  }

  const sortProducts = (option) => {
    switch (option) {
      case '1':
        setRooms([...rooms.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))]);
        break;
      case '2':
        setRooms([...rooms.sort((a, b) => a.dailyRent - b.dailyRent)]);
        break;
      case '3':
        setRooms([...rooms.sort((a, b) => b.dailyRent - a.dailyRent)]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(10, 0);
  }, [])

  useEffect(() => {
    if (rooms && rooms.length > 0) {
      setRooms(rooms)
      setSortBy(sortBy || 1)
      sortProducts(sortBy)
    }
  }, [rooms])


  return (
    <div className="wpo-room-area section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-12 col-md-12 col-sm-12">
            <SectionTitleS2 MainTitle={'Search Result'} />
          </div>
        </div>
        <div className='display-flex justify-flex-end align-items-center'>
          <p className="pr-10 m-0">Sort By</p>
          <Select
            labelId="sortBy"
            id="sortBy"
            value={sortBy}
            variant="outlined"
            name="sortBy"
            className=''
            label="Sort By"
            // InputLabelProps={{
            //     shrink: true,
            // }}
            onChange={(e) => changeHandler(e)}>
            {/* <MenuItem value='0'>Recommended</MenuItem> */}
            <MenuItem value='1'>Latest</MenuItem>
            <MenuItem value='2'>Price (Low to High)</MenuItem>
            <MenuItem value='3'>Price (High to Low)</MenuItem>
          </Select>
        </div>
        <br />
        <div className="room-wrap">
          <div className="row">
            {roomList.length > 0 &&
              roomList.map((ad, index) => (
                <div className="col-lg-4 col-md-6 col-12" key={index}>
                  <Link onClick={ClickHandler} to={`/details/${ad._id}`}>
                    <div className="room-item">
                      <div className="room-img">
                        <img className="height-200" src={ad?.images[0]} alt="" />
                      </div>
                      <div className="room-content">
                        <h2>{ad?.roomType} Room with {ad?.bathroomType} Bathroom
                          [{ad?.rentalOption === 'permanent' ? 'Permanent' : 'Temporary'} Accomodation]</h2>
                        <h4 style={titleStyle}>{eirCodeDecode(ad?.eircode) !== null ? eirCodeDecode(ad?.eircode) : ad?.city}</h4>
                        <ul>
                          <li><i className="fi flaticon-bed"></i>{ad.totalRoom} Bed</li>
                          <li><i className="fi flaticon-bathtub"></i>{ad.totalBathroom} Bathroom</li>
                        </ul>
                        <h3>€{ad.dailyRent} <span>/ Day</span></h3>
                        <div className="add-to-cart">
                          <button
                            className="theme-btn mt-3"
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Add to Cart"
                            onClick={() => redirectTo(ad._id)}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div >
    </div >
  );
};

export default SearchRooms;
