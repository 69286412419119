import React from 'react';
import AdminListingPage from './listing';


const AdminDashboard = (props) => {

    return (
        <AdminListingPage />
    )
};

export default AdminDashboard;