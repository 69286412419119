import React, { useState, Fragment, useEffect, useContext } from 'react';
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';
import { useDispatch } from 'react-redux';
import './style.scss';
import { addListingPage, updateListingPage } from '../../store/actions/addListingActions';
import CustomLoader from '../../components/CustomLoader';
import { Helmet } from "react-helmet";
import CustomTypeForm from '../../components/TypeForm';
import { ImageContext } from './ImageContext';

const TypeFormIndex = ({ cities, countries, value, setValue }) => {
    const push = useNavigate()
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const amentitiesIncluded = {
        electricity: false,
        television: false,
        wifi: false,
        heater: false,
        parking: false,
        bins: false
    };
    const nearBy = {
        lidl: false,
        tesco: false,
        aldi: false,
        busStop: false,
        luas: false,
        train: false,
        centra: false,
        circleK: false,
        superValue: false,
        dealz: false,
        indianStore: false,
        spar: false
    };
    const { images } = useContext(ImageContext);

    const updateObject = (array, object) => {
        const updatedObject = { ...object };
        for (const key in updatedObject) {
            updatedObject[key] = array.includes(key);
        }
        return updatedObject;
    };

    const updateDate = (stringDate) => {
        const dateString = stringDate;
        const parts = dateString.split("/");
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        const date = new Date(year, month - 1, day);
        return date.toISOString();
    }

    const submitForm = async (data) => {
        setLoading(true);
        if (localStorage.getItem('token')) {
            const updatedObject = {};
            for (const key in data) {
                if (key === 'amenitiesList') {
                    updatedObject[key] = updateObject(data[key].value, amentitiesIncluded);
                } else if (key === 'withInDistance') {
                    updatedObject[key] = updateObject(data[key].value, nearBy);
                } else if (key === 'startRentalDate' || key === 'endRentalDate') {
                    updatedObject[key] = updateDate(data[key].value);
                } else {
                    updatedObject[key] = data[key].value;
                }
            }
            if (images && images.length > 0) {
                updatedObject['images'] = images;
            }
            dispatch(addListingPage(updatedObject, push, setLoading));
        } else {
            toast.error('Please Sign In :(');
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>ToLetOut - Become a Host</title>
                <link rel="canonical" href="https://toletout.ie/addlisting" />
            </Helmet>
            <CustomLoader show={loading} />
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <CustomTypeForm submitForm={submitForm} cities={cities} countries={countries} />
        </Fragment>
    )
};

export default TypeFormIndex;