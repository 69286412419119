const initialState = {
    user: null, // Store user information when authenticated
    isAuthenticated: false,
};

const listingRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SEND_REQUEST':
            return {
                ...state,
                request: action.payload,
                isAuthenticated: true,
            };
        case 'UPDATE_VIEWING':
            return {
                ...state,
                request: action.payload,
                isAuthenticated: true,
            };
        default:
            return state;
    }
};

export default listingRequestReducer;
