import React, { useState, Fragment, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';
import { useDispatch } from 'react-redux';
import './style.scss';
import {
    Select,
    TextField,
    InputLabel,
    Button,
    IconButton,
    FormControl,
    MenuItem,
} from '@mui/material';
import { sendRequestPage } from '../../store/actions/listingRequestAction';

const ViewingRequest = (props) => {

    const location = useLocation();
    const { startDate, endDate, totalGuest } = location?.state;
    const { id } = useParams()

    const push = useNavigate()
    const dispatch = useDispatch();

    const [value, setValue] = useState({
        firstName: '',
        lastName: '',
        address: '',
        country: '',
        city: '',
        eircode: '',
        phone: '',
        email: '',
        aboutGuest: '',
        reference: '',
    });
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [images, setImages] = useState([]);

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const handleImageChange = async (e) => {
        const selectedImages = Array.from(e.target.files);
        const allowedFileTypes = ["jpg", "png", "jpeg", "gif"];
        const maxFileSize = 5 * 1024 * 1024;
        const validatedImages = selectedImages.filter((file) => {
            const fileExtension = file.name.split(".").pop().toLowerCase();
            const isValidType = allowedFileTypes.includes(fileExtension);
            const isSizeValid = file.size <= process.env.REACT_APP_FILE_SIZE_LIMIT || maxFileSize;

            if (!isValidType) {
                alert("File type not supported.");
            } else if (!isSizeValid) {
                alert("Please upload images less than 5MB.");
            }

            return isValidType && isSizeValid;
        });

        const imagePreviews = await Promise.all(
            validatedImages.map((file) => convertToBase64(file))
        );

        setImages((prevImages) => [...prevImages, ...imagePreviews]);
    };

    // Converting file to base64
    const convertToBase64 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
    }

    const handleRemoveImage = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            value.images = images;
            value['listId'] = id;
            value['startDate'] = startDate;
            value['endDate'] = endDate;
            value['totalGuest'] = totalGuest;
            dispatch(sendRequestPage(value, push))
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };

    // Fetch the country list
    const fetchCountries = async () => {
        try {
            const response = await fetch("https://countriesnow.space/api/v0.1/countries");
            if (response.ok) {
                const data = await response.json();
                setCountries(data.data);
                setValue({ ...value, country: 'Ireland' });
            } else {
                console.error("Failed to fetch countries data");
            }
        } catch (error) {
            console.error("Error fetching countries data: ", error);
        }
    };

    // fetch Cities
    const fetchCities = (country) => {
        const selectedCountry = countries.find((c) => c.country === country);
        setCities(selectedCountry?.cities || []);
    };

    // const formatDate = (date) => {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // }

    useEffect(() => {
        fetchCountries();
        fetchCities(value.country);
    }, [value.country]);

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            {/* <Grid className="listingWrapperBg"></Grid> */}
            <Grid className="listingWrapper">
                <Grid className="listingForm">
                    <h2>Your Details Please</h2>
                    <p className='mb-10'>Get started on your application by filling out this form.</p>
                    <p className='f-s-10 text-align-justify'>Your privacy matters to us. Your privacy is our priority, your personal information won't be disclosed.</p>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} xl={6} className='form-control-grid'>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="First Name"
                                    value={value.firstName}
                                    variant="outlined"
                                    name="firstName"
                                    label="First Name"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('firstName', value.firstName, 'required')}
                            </Grid>
                            <Grid item xs={12} xl={6} className='form-control-grid'>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Last Name"
                                    value={value.lastName}
                                    variant="outlined"
                                    name="lastName"
                                    label="Last Name"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('lastName', value.lastName, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    // rows={3}
                                    minRows={3}
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Address"
                                    value={value.address}
                                    variant="outlined"
                                    name="address"
                                    label="Address"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('address', value.address, 'required')}
                            </Grid>
                            <Grid item xs={12} xl={6} className='form-control-grid'>
                                {/* Country */}
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="country">Country</InputLabel>
                                    <Select
                                        labelId="country"
                                        id="country"
                                        value={value.country}
                                        variant="outlined"
                                        name="country"
                                        label="Country"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        onChange={(e) => changeHandler(e)}
                                    >
                                        <MenuItem value='Ireland'>Ireland</MenuItem>
                                    </Select>
                                </FormControl>
                                {validator.message('country', value.country, 'required')}
                            </Grid>
                            <Grid item xs={12} xl={6} className='form-control-grid'>
                                {/* City / County */}
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="city">City</InputLabel>
                                    <Select
                                        labelId="city"
                                        id="city"
                                        value={value.city}
                                        variant="outlined"
                                        name="city"
                                        label="City / County"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        onChange={(e) => changeHandler(e)}
                                    >
                                        {cities.map((city) => (
                                            <MenuItem key={`${city}`} value={city}>{city}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {validator.message('city', value.city, 'required')}
                            </Grid>
                            <Grid item xs={12} xl={6} className='form-control-grid'>
                                {/* Eircode */}
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Eircode"
                                    value={value.eircode}
                                    variant="outlined"
                                    name="eircode"
                                    label="Eircode"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('eircode', value.eircode, 'required')}
                            </Grid>
                            <Grid item xs={12} xl={6} className='form-control-grid'>
                                {/* Phone */}
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Phone Number"
                                    value={value.phone}
                                    variant="outlined"
                                    name="phone"
                                    type='number'
                                    label="Phone Number"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('phone', value.phone, 'required')}
                            </Grid>
                            <Grid item xs={12} xl={12}>
                                {/* Phone */}
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Email"
                                    value={value.email}
                                    variant="outlined"
                                    name="email"
                                    type='email'
                                    label="Email"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('email', value.email, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                {/* About You */}
                                <TextField
                                    multiline
                                    // rows={3}
                                    minRows={3}
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Tell us more About You"
                                    value={value.aboutGuest}
                                    variant="outlined"
                                    name="aboutGuest"
                                    label="Tell us more About You"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('aboutGuest', value.aboutGuest, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="guestImages">Please upload Your ID (For Verification)</InputLabel>
                                <TextField
                                    id='guestImages'
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Upload Image/s"
                                    name="guestImages"
                                    // label="Upload Image/s"
                                    // InputLabelProps={{
                                    //     shrink: true,
                                    // }}
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .gif"
                                    inputProps={{ multiple: true }}
                                    variant="outlined"
                                    onChange={handleImageChange}
                                />
                                {validator.message('guestImages', images, 'required')}
                                <Grid container spacing={2}>
                                    {images.map((image, index) => (
                                        <Grid item key={index}>
                                            <div className="image-container position-relative">
                                                <img
                                                    src={image}
                                                    alt={`Image ${index}`}
                                                    style={{ maxWidth: "100px" }}
                                                />
                                                <IconButton
                                                    size="small"
                                                    color="secondary"
                                                    className="position-absolute background-white rt-0"
                                                    onClick={() => handleRemoveImage(index)}
                                                >
                                                    <i className="fa fa-close color-black" />
                                                </IconButton>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {/* How did you hear */}
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="reference">How did you hear about us?</InputLabel>
                                    <Select
                                        labelId="reference"
                                        id="reference"
                                        value={value.reference}
                                        variant="outlined"
                                        name="reference"
                                        className='w-100'
                                        label="How did you hear about us?"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        onChange={(e) => changeHandler(e)}
                                    >
                                        <MenuItem value='instagram'>Instagram</MenuItem>
                                        <MenuItem value='facebook'>Facebook</MenuItem>
                                        <MenuItem value='searchEngine'>Search Engine (Google, Yahoo etc)</MenuItem>
                                        <MenuItem value='other'>Other</MenuItem>
                                    </Select>
                                </FormControl>
                                {validator.message('reference', value.reference, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formFooter">
                                    <Button fullWidth className="cBtnTheme" type="submit">Submit</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <div className="shape-img">
                        <i className="fi flaticon-honeycomb"></i>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default ViewingRequest;