import React, { Fragment, useState } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import './style.scss';
import { useDispatch } from 'react-redux';
import { forgetPassword } from '../../store/actions/authActions';
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo2.png';

const ForgotPassword = (props) => {

    const push = useNavigate()
    const dispatch = useDispatch();

    const [value, setValue] = useState({
        email: '',
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setValue({
                email: '',
            });
            dispatch(forgetPassword(value));
            toast.success('Please check your email to reset the password');
            validator.hideMessages();
            push('/login');
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <Grid className="forgetWrapper">
                <Grid className="loginForm">
                    <h2>Forgot Password</h2>
                    <p>Reset your account password</p>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="E-mail"
                                    value={value.email}
                                    variant="outlined"
                                    name="email"
                                    label="E-mail"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('email', value.email, 'required|email')}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formFooter">
                                    <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">Resend
                                        Password</Button>
                                </Grid>
                                {/* <Grid className="loginWithSocial">
                                    <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                    <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                    <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                                </Grid> */}
                                <p className="noteHelp">Already have an account? <Link to="/login">Return to Sign In</Link>
                                </p>
                            </Grid>
                        </Grid>
                    </form>
                    <div className="shape-img">
                        <i className="fi flaticon-honeycomb"></i>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default ForgotPassword;