import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from '../HomePage'
import AboutPage from '../AboutPage/AboutPage';
import RoomSinglePage from '../RoomSinglePage';
import SearchResults from '../SearchResults/SearchResults';
import LoginPage from '../LoginPage'
import SignUpPage from '../SignUpPage'
import ForgotPassword from '../Profile/forgetPassword';
import ErrorPage from '../ErrorPage/ErrorPage';
import ContactPage from '../ContactPage/ContactPage';
import AddListing from '../AddListing';
import UserListingPage from '../UserListingPage';
import ViewingRequest from '../ViewRequest';
import FAQs from '../FAQs';
import AdminDashboard from '../AdminDashboard';
import RoomPage from '../RoomPage';
import TermsAndCondition from '../termsAndCondition';
import VerifyAccount from '../Profile/verifyAccount';
import ChangePassword from '../Profile/changePassword';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='search-result' element={<SearchResults />} />
          <Route path='details/:id' element={<RoomSinglePage />} />
          <Route path='my-listing' element={<UserListingPage />} />
          <Route path='*' element={<ErrorPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='register' element={<SignUpPage />} />
          <Route path='room' element={<RoomPage />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path='addlisting' element={<AddListing />} />
          <Route path='addlisting/:id' element={<AddListing />} />
          <Route path='viewingRequest/:id' element={<ViewingRequest />} />
          <Route path='faq' element={<FAQs />} />
          <Route path='admin-dashboard-secret' element={<AdminDashboard />} />
          <Route path='termsAndCondition' element={<TermsAndCondition />} />
          <Route path='verifyaccount' element={<VerifyAccount />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
