import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const Rooms = (props) => {

    const [adDetails, setAdDetails] = useState(props?.adDetails || {})
    var settings = {
        dots: false,
        arrows: true,
        speed: 1200,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 5000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        setAdDetails(props?.adDetails)
    }, [props?.adDetails]);

    return (
        <div className="wpo-room-area-s3 section-padding pt-0">
            <div className="container-fluid">
                <div className="room-wrap room-active">
                    <Slider {...settings}>
                        {adDetails?.images && adDetails?.images?.length > 0 ?
                            adDetails.images.map((image, index) => (
                                <div className="room-item" key={index}>
                                    <div className="room-img">
                                        <Zoom>
                                            <LazyLoadImage
                                                //  height-200
                                                className="image-details w-100"
                                                // ${isShown ?  : null
                                                // className={`width-${maxImageWidth} height-${parseInt(window.innerHeight - 25)} ${maxWidth <= 0 ? `max-width-${maxWidth}` : `max-width-${maxWidth}`} max-height-${maxImageHeight} cp`}
                                                referrerPolicy="no-referrer"
                                                src={image}
                                                alt=""
                                                effect="blur"
                                            // width="100%"
                                            />
                                            {/* <img className="image-details"
                                                src={image}
                                                alt=""
                                            /> */}
                                        </Zoom>
                                    </div>
                                </div>
                            )) : null}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Rooms;
